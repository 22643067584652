import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-community-home',
  templateUrl: './community-home.component.html',
  styleUrls: ['./community-home.component.sass']
})
export class CommunityHomeComponent implements OnInit {

  communitySwiper = {
    index: 0,
    config: {
      speed: 1000,
      initialSlide: 0,
      slidesPerView: 3,
      spaceBetween: 15,
      simulateTouch: false
    },
    data: [
      {
        description: 'Cuando un vecino me recomendó Munily, no dude que sería de gran ayuda. Ha facilitado la vida a los residentes y a la administración, manteniendo la seguridad y el buen servicio como premisa.',
        'title': 'Juan Posada',
        'img': 'assets/img/community/1.png',
        'short': 'Presidente de la Junta Directiva del Embassy Club'
      },
      {
        description: 'Munily es rápido, fácil y sencillo. Los residentes buscan: seguridad, comunicación con guardias y saber quién entra a su hogar, Munily ofrece las tres opciones. El sistema me encanta.',
        'title': 'Manuel Castellano',
        'img': 'assets/img/community/2.png',
        'short': 'Guardia de seguridad.'
      },
      {
        description: 'Me parece bastante útil, Munily permite tener el registro de todos los movimientos del edificio e interactuar con el co-propietario para atender sus solicitudes de forma rápida y segura.',
        'title': 'Walther Pérez',
        'img': 'assets/img/community/3.png',
        'short': 'Administrador del P.H. Vita'
      }
    ]
  };

  constructor() { }

  ngOnInit(): void {
  }

}
