<div *ngIf="!notHeader" id="main-header">
  <div class="container">
    <div class="row main-header__row">
      <div class="col-auto main-header__logo-col">
        <a
          routerLink="/"
          rel="noreferrer"
          class="main-header__logo-anchor"
          aria-label="Volver al inicio"
        >
          <img src="assets/img/logo.svg" class="main-header__logo" />
        </a>
      </div>

      <div class="col-auto main-header__menu">
        <!-- <button class="main-header__button" aria-label="Menu" (click)="toggleMenu()">
                    <span class="main-header__button-line"></span>
                    <span class="main-header__button-line"></span>
                    <span class="main-header__button-line"></span>
                </button> -->

        <a
          routerLink="/plans/list"
          target="_blank"
          aria-label="Planes"
          rel="noreferrer"
          class="main-header__link"
          >Planes</a
        >

        <a
          href="https://blog.munily.com/"
          target="_blank"
          aria-label="Conferencias"
          rel="noreferrer"
          class="main-header__link"
          >Blog</a
        >

        <a
          routerLink="/"
          aria-label="Planes"
          rel="noreferrer"
          class="main-header__link"
          >Nosotros</a
        >
        <!-- <a routerLink="/us" target="_blank" aria-label="Planes" rel="noreferrer" class="main-header__link">Nosotros</a> -->

        <a
          href="https://intercom.help/munily/es/"
          target="_blank"
          aria-label="Ayuda"
          rel="noreferrer"
          class="main-header__link"
          >Ayuda</a
        >
        <a
          href="https://api.whatsapp.com/send/?phone=50768436859&text=%C2%A1Hola%21+Me+interesa+saber+m%C3%A1s+de+Munily&app_absent=0"
          target="_blank"
          rel="noreferrer"
          aria-label="Whatsapp"
          class="main-header__link"
          >Whatsapp</a
        >
        <a
          href="#"
          aria-label="Idioma"
          rel="noreferrer"
          class="main-header__link dropdown"
        >
          Es
          <div class="main-header__dropdown">
            <a href="#" rel="noreferrer" class="main-header__link">En</a>
          </div>
        </a>
        <a
          href="https://dashboard.munily.com/"
          aria-label="Acceder"
          rel="noreferrer"
          class="main-header__link blue-btn"
          >Iniciar sesión</a
        >
      </div>

      <div class="col-auto main-header__toggle">
        <button
          class="main-header__button"
          aria-label="Menu"
          (click)="toggleMenu()"
        >
          <span class="main-header__button-line"></span>
          <span class="main-header__button-line"></span>
          <span class="main-header__button-line"></span>
        </button>
      </div>
    </div>
  </div>
</div>
