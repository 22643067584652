import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {
  List,
  MapperResponseMunilyTrucks,
  MunilyTruckImageType,
  MunilyTrucks,
  ResponseCategoriesBuilding,
  ResponseMunilyTrucks,
  SavingsParams,
} from '../interfaces/MunilyTrucksinterface';

@Injectable({
  providedIn: 'root',
})
export class PublicService {
  constructor(private http: HttpClient) {}

  getTyC() {
    return this.http.get(`${environment.apiUrl}public/v1.2/terms`);
  }

  getTyCFrequentAccess() {
    return this.http.get(`${environment.apiUrl}public/v1.2/terms/frequent-access`);
  }

  getTyCMunilyPay() {
    return this.http.get(`${environment.apiUrl}public/v1.2/terms/munily-pay`)
  }

  getFrequentAccessData(token) {
    let params = new HttpParams().append("token", token)
    return this.http.get(`${environment.apiUrl}public/v1/frequent-access`, { params });
  }

  searchCategoriesByBilding(
    buildingId: string,
    searchCategory: string
  ): Observable<MunilyTrucks[]> {
    const params = new HttpParams().set('searchCategory', searchCategory);
    return this.http
      .get<ResponseMunilyTrucks>(
        `${environment.apiUrl}public/v1/services/search/${buildingId}/categories`,
        { params }
      )
      .pipe(map((data) => data.data));
  }

  searchServicesByCategory(
    categoryId: string,
    searchCategory: string
  ): Observable<MunilyTrucks[]> {
    const params = new HttpParams().set('searchServices', searchCategory);
    return this.http
      .get<ResponseMunilyTrucks>(
        `${environment.apiUrl}public/v1/services/search/${categoryId}/services`,
        { params }
      )
      .pipe(map((data) => data.data));
  }

  getCategoriesByBuilding(buildingId: string): Observable<List[]> {
    return this.http
      .get<ResponseCategoriesBuilding>(
        `${environment.apiUrl}public/v1/services/${buildingId}/categories`
      )
      .pipe(map((data) => data.data));
  }

  getServicesByCategories(categoryId: string): Observable<MunilyTrucks[]> {
    return this.http
      .get<ResponseMunilyTrucks>(
        `${environment.apiUrl}public/v1/services/${categoryId}/services`
      )
      .pipe(map((data) => data.data));
  }

  getServicesByBuildingAndCategory(
    buildingId: string,
    categoryId: string
  ): Observable<MunilyTrucks[]> {
    return this.http
      .get<ResponseMunilyTrucks>(
        `${environment.apiUrl}public/v1/services/building/${buildingId}/category/${categoryId}`
      )
      .pipe(map((data) => data.data));
  }

  mapperMunilyTrucksBuilding(
    services: MunilyTrucks[],
    queryParams: SavingsParams
  ): MapperResponseMunilyTrucks[] {
    return services.map((service) => {
      const images =
        service.images.find(
          (image) => image.type === MunilyTruckImageType.type4
        )?.file.url ||
        service.images.find(
          (image) => image.type === MunilyTruckImageType.type1
        )?.file.url;

      if (service.link.endsWith('/')) {
        service.link = service.link.replace(/[/]$/, '');
      }
      if (service.link.match(/[?]/)) {
        service.link = `${service.link}&`;
      } else {
        service.link = `${service.link}?`;
      }

      const params = `userId=${queryParams.userId}&userName=${queryParams.userName}&userEmail=${queryParams.userEmail}&address=${queryParams.address}&userPhone=${queryParams.userPhone}&buildingId=${queryParams.buildingId}&latitudBuilding=${queryParams.latitudBuilding}&longitudBuilding=${queryParams.longitudBuilding}&provider=munily`;
      return {
        name: service.name,
        description: service.description,
        link: `${service.link}${params}`,
        images,
      };
    });
  }

  fromBase64ToImageFormat(base64: string) {
    const format = base64.split(';')[0].split('/')[1];
    return {
      format: format ? format : 'png',
      base64,
    };
  }

  getConfigTimeQR(){
    return this.http.get(`${environment.apiUrl}public/v1/generalconfig/configuration-time-qr`);
  }

  getFrequentAccessLanguage(token: string){
    const params: HttpParams = new HttpParams().set('token', token);
    return this.http.get(`${environment.apiUrl}public/v1/frequent-access/language`, {params});
  }

  updatePlateVehicleQpass(token: string, payload: { plate: string }) {
    return this.http.patch(`${environment.apiUrl}public/v1/frequent-access/${token}/plate`, payload);
  }

  deletePlateVehicleQpass(token: string) {
    return this.http.delete(`${environment.apiUrl}public/v1/frequent-access/${token}/plate`);
  }
}
