import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class MainService {
  socket: Socket;
  constructor(private http: HttpClient) {}

  freeTestApp(formData) {
    return this.http
      .post(environment.apiUrl + 'tmp-building', formData)
      .toPromise();
  }

  sendFormToCRM( data, formId) {
    let urlCreate = `${environment.urlCrmHubspot}/${environment.portalId}/${formId}`;
    return this.http.post(`${urlCreate}`, data);
  }

  getCountry(size:string){
    let params = new HttpParams();
    if (size) params = params.set('limit', size);
    return this.http.get(`${environment.apiUrl}public/v1/countries/paginate`, { params:params });
  }

  connect(token:string) {
    this.socket = new Socket({
      url: environment.socketUrl + '/qpass/invitation',
      options: {
        query: {
          token: token
        },
        transports: ['websocket']
      }
    });
  }

  disconnect(){
    this.socket.removeAllListeners();
    this.socket.disconnect();
  }
}
