<section class="section__main">
    <div class="row section__top">
        <div class="col-lg-6 mt-3 movil-center">
            <h1 class="section__title mt-4 mb-4">Comunidades seguras y conectadas siempre.</h1>
            <img src="../../../assets/svg/logo_1.svg" class="img-fluid section__mobile" alt="Munily">
            <div class="swiper-container section__swiper" [swiper]="textSwiper.config" [(index)]="textSwiper.index"
                (slideChangeTransitionStart)="slideChanged()">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <p class="section__paragrahp">Administración de residencias 100% remota.</p>
                    </div>
                    <div class="swiper-slide">
                        <p class="section__paragrahp">Conexión entre residentes, guardias de seguridad y la
                            administración a través de una aplicación móvil.</p>
                    </div>
                    <div class="swiper-slide">
                        <p class="section__paragrahp">Asistencia online a residentes.</p>
                    </div>
                    <div class="swiper-slide">
                        <p class="section__paragrahp">Control de la comunidad en una app móvil.</p>
                    </div>
                </div>
            </div>
            <div class="row mt-5 mb-5">
                <div class="col-lg-12 section__center">
                    <a [routerLink]="['/contactus']" class="btn btn-info btn-lg btn__btn-munily">
                        <span class="btn__text">Solicitar una demo</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="swiper-container section__img-swiper" [swiper]="imgSwiper.config" [(index)]="textSwiper.index"
                (slideChangeTransitionStart)="slideImgChanged()">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <img src="../../../assets/svg/logo_1.svg" class="img-fluid section__desktop" alt="Munily">
                    </div>
                    <div class="swiper-slide">
                        <img src="../../../assets/svg/logo_1.svg" class="img-fluid section__desktop" alt="Munily">
                    </div>
                    <div class="swiper-slide">
                        <img src="../../../assets/svg/logo_1.svg" class="img-fluid section__desktop" alt="Munily">
                    </div>
                    <div class="swiper-slide">
                        <img src="../../../assets/svg/logo_1.svg" class="img-fluid section__desktop" alt="Munily">
                    </div>
                </div>
            </div>
            <!-- <img src="../../../assets/svg/logo_1.svg" class="img-fluid section__desktop" alt="Munily"> -->
        </div>
    </div>
</section>