<section class="home-benefits">
    <div class="container">
        <div class="row">
            <div class="col-12 home-benefits__item">
                <div class="row">
                    <div class="col-lg-5">
                        <h3>Administra tu residencia desde una App</h3>
                        <p>Munily es la plataforma para todo lo relacionado con la gestión y administración de
                            comunidades.</p>
                    </div>
                    <div class="col-lg-7">
                        <img src="assets/img/home-benefits/administracion-min.jpg" class="img-fluid"
                            alt="Administra tu residencia desde una App">
                    </div>
                </div>
            </div>
            <div class="col-12 home-benefits__item">
                <div class="row">
                    <div class="col-lg-6">
                        <img src="assets/img/home-benefits/conexion-min.jpg" class="img-fluid"
                            alt="Mantén conectada a toda tu comunidad">
                    </div>
                    <div class="col-lg-5 offset-lg-1">
                        <h3>Mantén conectada a toda tu comunidad</h3>
                        <p>Una mejor comunicación y seguridad en un solo lugar.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 home-benefits__item">
                <div class="row">
                    <div class="col-lg-5">
                        <h3>Transforma la seguridad residencial</h3>
                        <p>A medida que lleguen personas o paquetes, los guardias podrán documentar y comunciar
                            registros a los residentes en un 2x3.</p>
                    </div>
                    <div class="col-lg-5 offset-lg-1">
                        <img src="assets/img/home-benefits/seguridad-min.jpg" class="img-fluid"
                            alt="Transforma la seguridad residencial">
                    </div>
                </div>
            </div>
            <div class="col-12 home-benefits__item">
                <div class="row">
                    <div class="col-lg-5 offset-lg-1">
                        <img src="assets/img/home-benefits/residencia-min.jpg" class="img-fluid"
                            alt="Disfruta de la mejor experiencia residencial posible">
                    </div>
                    <div class="col-lg-5 offset-lg-1">
                        <h3>Disfruta de la mejor experiencia residencial posible</h3>
                        <p>En Munily apostamos por la facilidad y la rapidez que hasta a los invitados les garantizamos
                            una experiencia increible.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>