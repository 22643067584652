export const environment = {
  production: true,
  apiUrl: 'https://api-stg.munily.xyz/api/',
  urlCrmHubspot: 'https://api.hsforms.com/submissions/v3/integration/submit',
  socketUrl: 'https://socket-stg.munily.xyz',
  portalId: 5428688,
  firebase: {
    apiKey: 'AIzaSyBkEM9yTL3w_GBx-JNb20ySK7IPvp_LHcA',
    authDomain: 'munily-dev-f8b14.firebaseapp.com',
    databaseURL: 'https://munily-test.firebaseio.com',
    projectId: 'munily-dev-f8b14',
    storageBucket: 'munily-dev-f8b14.appspot.com',
    messagingSenderId: '263851461501',
  },
  formIdColombia: 'cd62fc50-c80a-4f5c-9528-11455196dfa5',
  formIdCostaRica: 'f24d309d-02ce-4e35-bb86-0bb192bfdddf',
  formIdPaginaPrincipal: '1bea2b18-b68d-4b43-a63f-ac930b30aa97',
  formIdSage50: 'e62af1f5-3bb0-4397-8fba-a857fec100e1',
  amplitudeApiKey: '46c912ce0505f555a0cfc2682c2a2641',
  amplitudeEnabled: true,
  envDeployed: 'stg',
};
