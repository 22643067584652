import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';

export enum callsType {
  sendCompleteData = 'sendCompleteData',
  sendSimpleAction = 'sendSimpleAction',
  sendError = 'sendError'
};

@Injectable({
  providedIn: 'root'
})
export class DataDogService {
  constructor() { }

  sendEventToDataDog(key:string | Error, calltype: callsType, value?:any){
    switch (calltype) {
      case callsType.sendCompleteData:
        this.sendCompleteData(key as string,value);  
      break;
      case callsType.sendSimpleAction:
        this.sendSimpleAction(key as string);  
      break;
      case callsType.sendError:
        this.sendError(key as Error);  
      break;
    }
  }

  sendCompleteData(key:string, value:any){
    datadogRum.addRumGlobalContext(key, value);
  }

  sendSimpleAction(action:string){
    datadogRum.addAction(action);
  }

  sendError(err:Error){
    datadogRum.addError(err);
  }
}
