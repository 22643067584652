<div class="footer__container" [ngClass]="{ 'footer__rm-height': hasPath }">
  <div class="container">
    <div class="row m-0" [hidden]="hasPath">
      <div class="col-12 col-md-6 col-lg-4 offset-lg-1">
        <div class="footer__container-text">
          <div class="footer__container-text__title">Únete a Munily</div>
          <div class="footer__container-text__msg">
            Comunidades seguras y conectadas
          </div>
        </div>
        <div class="footer__container-img">
          <a
            href="https://play.google.com/store/apps/details?id=com.munily.movil&hl=es_PA"
            target="_blank"
            class="footer__android"
            ><img src="../../../assets/img/android.png" class="img-fluid"
          /></a>
          <a
            href="https://apps.apple.com/pa/app/munily/id1434349453"
            target="_blank"
            class="footer__iphone"
            ><img src="../../../assets/img/apple.svg" class="img-fluid"
          /></a>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-5 offset-lg-1 text-center">
        <div class="footer__container-phone mt-5">
          <img src="../../../assets/svg/iphone.svg" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>

  <div class="footer__container-contact">
    <div class="footer__main-content">
      <div class="row m-0">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 text-center">
          <div class="row m-0">
            <div class="col-12 p-0 footer__img-center">
              <img
                src="../../../assets/img/munily logo_logo.png"
                class="footer__img"
              />
            </div>
            <div class="col-12 p-1 mt-2 footer__desktop">
              <p class="footer__text">Noticias</p>
              <p class="footer__text">Blog</p>
            </div>
            <div class="col-12 p-0 mt-2 footer__desktop">
              <ul class="nav">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="https://www.facebook.com/Munily-App-101110371523119/"
                    target="_blank"
                    ><img src="../../../assets/svg/facebook-with-circle.svg"
                  /></a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="https://twitter.com/munily_app"
                    target="_blank"
                    ><img src="../../../assets/svg/twitter-with-circle.svg"
                  /></a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="https://www.instagram.com/munily_app/"
                    target="_blank"
                    ><img src="../../../assets/svg/instagram-with-circle.svg"
                  /></a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="https://www.linkedin.com/company/28160008/admin/"
                    target="_blank"
                    ><img src="../../../assets/svg/linkedin-with-circle.svg"
                  /></a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="https://wa.me/50768436859?text=%C2%A1Hola!%20Me%20interesa%20saber%20m%C3%A1s%20de%20Munily"
                    target="_blank"
                    ><img src="../../../assets/svg/whatsapp-with-circle.svg"
                  /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 text-center footer__desktop"
        >
          <div class="col-12">
            <p class="footer__text">&nbsp;</p>
          </div>
          <div class="col-12 p-1 mt-4">
            <p class="footer__text">About Us</p>
            <p class="footer__text">Terms and Conditions</p>
            <p class="footer__text">Privacy Policy</p>
          </div>
        </div>
        <div
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 text-center footer__desktop"
        >
          <div class="col-12">
            <p class="footer__text">&nbsp;</p>
          </div>
          <div class="col-12 p-1 mt-4">
            <p class="footer__text">Help</p>
            <p class="footer__text">FAQ</p>
            <p class="footer__text">Contact Us</p>
          </div>
        </div>
        <div
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 text-center footer__mobile"
        >
          <div class="col-12">
            <p class="footer__text">&nbsp;</p>
          </div>
          <div class="col-12 p-1 mt-4">
            <p class="footer__text">About Us</p>
            <p class="footer__text">Terms and Conditions</p>
            <p class="footer__text">Privacy Policy</p>
            <p class="footer__text">Help</p>
            <p class="footer__text">FAQ</p>
            <p class="footer__text">Contact Us</p>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 text-center">
          <div class="col-12 p-0 text-left">
            <h4 class="footer__title-contact">Informacion de Contacto</h4>
          </div>
          <div class="col-12 p-1 mt-4">
            <p class="footer__text-contact">
              Tlf: <a href="tel:+50766587864">+507 6658-7864</a>
            </p>
            <p class="footer__text-contact">
              Email: <a href="mailto:info@munily.com">info@munily.com</a>
            </p>
            <p class="footer__text-contact">
              Torre Bicsa, Avenida Balboa, Piso 43 - Oficina 03 Ciudad de
              Panamá.
            </p>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 footer__mobile">
          <ul class="nav">
            <li class="nav-item">
              <a
                class="nav-link"
                href="https://www.facebook.com/Munily-App-101110371523119/"
                target="_blank"
                ><img src="../../../assets/svg/facebook-with-circle.svg"
              /></a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="https://twitter.com/munily_app"
                target="_blank"
                ><img src="../../../assets/svg/twitter-with-circle.svg"
              /></a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="https://www.instagram.com/munily_app/"
                target="_blank"
                ><img src="../../../assets/svg/instagram-with-circle.svg"
              /></a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="https://www.linkedin.com/company/28160008/admin/"
                target="_blank"
                ><img src="../../../assets/svg/linkedin-with-circle.svg"
              /></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="footer__bottom-content text-center footer__desktop">
    <p class="footer__text-bottom">Hecho con 💙 desde latam para el mundo.</p>
  </div>
</div>
