<!-- navbar -->
<app-navbar></app-navbar>
<!-- end navbar -->

<!-- content -->
<div class="container-fluid">

    <!-- sectionContact -->
    <div class="row m-0">
        <div class="col-xs-12 col-sm-12 col-lg-6 p-0 info-full">
            <app-section-contact></app-section-contact>
        </div>
        <div class="col-xs-12 col-sm-12 col-lg-6 p-0">
            <app-form-contact></app-form-contact>            
        </div>
        <div class="col-xs-12 col-sm-12 col-lg-6 p-0 info-resp">
            <app-section-contact></app-section-contact>            
        </div>
    </div>
    
    <!-- end sectionContact -->

    <!-- Footer -->
    <app-footer></app-footer>
    <!-- end Footer -->
</div>

