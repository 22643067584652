
export interface Fields{
  name: string;
  value: string;
}

export enum errorValidInput {
  country = "Pais",
  city = "Ciudad",
  name = "Nombre",
  position = "Cargo",
  email = "Correo Electrónico",
  phone = "Teléfono",
  serviceNeed = "¿Qué tanto necesitas este servicio?",
  howYouKnowWe = "¿Cómo nos conociste?",
  experienceDoYouHave = "¿Cuánta experiencia tienes en tu cargo?"
}

export enum campoNameHubspot {
  country = "pais",
  city = "city",
  name = "firstname",
  jobTitle = "jobtitle",
  email = "email",
  phone = "mobilephone",
  serviceNeed = "que_tanto_necesito_un_servicio_como_munily_en_mi_comunidad_residencial_",
  howYouKnowWe = "como_nos_conociste_",
  experience = "cuanta_experiencia_dentro_de_tu_rol_tienes_"
}

export interface CrmFormInterface {


  submittedAt: string;
  fields: Fields[];

    context: {
      hutk?: string,
      pageUri: string,
      pageName: string
    };
    legalConsentOptions?: {
      consent: {
        consentToProcess: boolean,
        text: string,
        communications: [
          {
            value: boolean,
            subscriptionTypeId: number,
            text: string
          }
        ]
      }
    };

}
