import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { trigger, state, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-section-two',
  templateUrl: './section-two.component.html',
  styleUrls: ['./section-two.component.scss'],
  animations: [
    trigger('visibilityChanged', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('shown => hidden', animate('600ms')),
      transition('hidden => shown', animate('300ms')),
    ])
  ]
})

export class SectionTwoComponent implements OnInit {
  visiblityState = 'shown';

  @ViewChild('videoPlayer') videoplayer: ElementRef;

  constructor() { }

  ngOnInit() {
  }

  playVideo():void {
    const video = this.videoplayer.nativeElement;

    if(video.paused){
      video.play();
      this.visiblityState = 'hidden';
    }else{
      video.pause();
      this.visiblityState = 'shown';
    }
  }

}
