<!-- <section class="pre-footer">
    <div class="container">
        <div class="row">
            <div class=" col-md-8 pre-footer__text">
                <p class="d-sm-none d-md-block">Solicite una demo con nuestros expertos</p>
            </div>
            <div class="col-md-4 col-12 pre-footer__button">
                <a [routerLink]="['/contact-us']" routerLinkActive="router-link-active" aria-label="Solicitar una demo"
                    class="blue-btn">Solicitar una demo</a>
            </div>
        </div>
    </div>
</section> -->

<footer *ngIf="notFooter">
  <div class="container">
    <div class="row">
      <div class="col-xl-8">
        <div class="row">
          <div class="col-12 footer__logo-col">
            <a href="#" aria-label="Volver al inicio" rel="noreferrer">
              <img
                src="assets/img/logo-white.svg"
                alt=""
                class="footer__logo"
              />
            </a>
          </div>
          <div class="col-auto footer__col">
            <a
              href="https://blog.munily.com/"
              aria-label="Conferencias"
              rel="noreferrer"
              >Conferencias</a
            >
            <a href="#" aria-label="Nosotros" rel="noreferrer">Nosotros</a>
            <div class="footer__rrss">
              <a
                href="https://www.facebook.com/Munily-App-101110371523119/"
                aria-label="Facebook"
                rel="noreferrer"
              >
                <img src="assets/img/facebook.png" alt="" />
              </a>
              <a
                href="https://twitter.com/munily_app"
                aria-label="Twitter"
                rel="noreferrer"
              >
                <img src="assets/img/twitter.png" alt="" />
              </a>
              <a
                href="https://www.instagram.com/munily_app/"
                aria-label="Instagram"
                rel="noreferrer"
              >
                <img src="assets/img/instagram.png" alt="" />
              </a>
              <a
                href="https://www.linkedin.com/company/28160008/admin/"
                aria-label="Linkedin"
                rel="noreferrer"
              >
                <img src="assets/img/linkedin.png" alt="" />
              </a>
              <a
                href="https://api.whatsapp.com/send/?phone=50768436859&text=%C2%A1Hola%21+Me+interesa+saber+m%C3%A1s+de+Munily&app_absent=0"
                aria-label="Whatsapp"
                rel="noreferrer"
                target="_blank"
              >
                <img src="assets/img/whatsapp.png" alt="" />
              </a>
            </div>
          </div>
          <div class="col-auto footer__col">
            <!-- <a href="#">Terms & Conditions</a> -->
            <a
              href="./terms/data-treatment"
              aria-label="Términos y Condiciones"
              rel="noreferrer"
              >Términos y Condiciones</a
            >

            <a
              href="./terms/data-treatment?type=privacity"
              aria-label="Términos y Condiciones"
              rel="noreferrer"
              >Política de Privacidad</a
            >

            <a
              href="https://intercom.help/munily/es/"
              target="_blank"
              aria-label="Ayuda"
              rel="noreferrer"
              >Ayuda</a
            >
          </div>
          <div class="col-auto footer__col">
            <a
              href="#"
              (click)="scrollTo('.faqs')"
              aria-label="Q&A"
              rel="noreferrer"
              >Q&A</a
            >
            <a
              href="https://api.whatsapp.com/send/?phone=50768436859&text=%C2%A1Hola%21+Me+interesa+saber+m%C3%A1s+de+Munily&app_absent=0"
              aria-label="Whatsapp"
              rel="noreferrer"
              >Whatsapp</a
            >
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-12 footer__headquarters">
        <p class="footer__title">Información de Contacto</p>
        <ul>
          <li>
            <p>Tlf: +507 6658-7864</p>
            <p>Email: info@munily.com</p>
            <p>
              Torre Bicsa, Avenida Balboa, Piso 43 - Oficina 03 Ciudad de
              Panamá.
            </p>
          </li>
          <li>
            <p>Email: info@munily.com</p>
            <p>Calle 69a # 5-59 piso 2 Bogotá</p>
          </li>
        </ul>
      </div>
      <div class="col-xl-2 col-12 footer__partners">
        <p class="footer__title">Socios y Aliados</p>
        <div>
          <a
            href="https://ciudaddelsaber.org/"
            aria-label="Ciudad del saber"
            rel="noreferrer"
            target="_blank"
          >
            <img src="assets/img/footer/partner-1.png" alt="" />
            <img src="assets/img/footer/partner-1-mobile.png" alt="" />
          </a>
          <p>Ciudad del Saber</p>
        </div>
        <div>
          <a
            href="https://expertdojo.com/"
            aria-label="Expert Dojo"
            rel="noreferrer"
            target="_blank"
          >
            <img src="assets/img/footer/partner-2.png" alt="" />
            <img src="assets/img/footer/partner-2-mobile.png" alt="" />
          </a>
          <p>Expert Dojo</p>
        </div>
      </div>
      <div class="col-xl-2 col-12 footer__rrss">
        <a
          href="https://www.facebook.com/Munily-App-101110371523119/"
          aria-label="Facebook"
          rel="noreferrer"
        >
          <img src="assets/img/facebook.png" alt="" />
        </a>
        <a
          href="https://twitter.com/munily_app"
          aria-label="Twitter"
          rel="noreferrer"
        >
          <img src="assets/img/twitter.png" alt="" />
        </a>
        <a
          href="https://www.instagram.com/munily_app/"
          aria-label="Instagram"
          rel="noreferrer"
        >
          <img src="assets/img/instagram.png" alt="" />
        </a>
        <a
          href="https://www.linkedin.com/company/28160008/admin/"
          aria-label="Linkedin"
          rel="noreferrer"
        >
          <img src="assets/img/linkedin.png" alt="" />
        </a>
        <a
          href="https://api.whatsapp.com/send/?phone=50768436859&text=%C2%A1Hola%21+Me+interesa+saber+m%C3%A1s+de+Munily&app_absent=0"
          aria-label="Whatsapp"
          rel="noreferrer"
        >
          <img src="assets/img/whatsapp.png" alt="" />
        </a>
      </div>
    </div>
  </div>
</footer>

<section *ngIf="notFooter" class="post-footer">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <p>Hecho con 💙 desde latam para el mundo.</p>
      </div>
    </div>
  </div>
</section>
