<section class="community-home">
    <img src="assets/img/community/path-top-blue.png" alt="" class="start-section">

    <div class="community-home__content">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 col-12 community-home__heading">
                    <h3>Vive en <span class="highlight">comunidad</span> como nunca antes</h3>
                    <p>Trabajamos para crear comunidades seguras y conectadas empezando residencia por residencia.</p>
                </div>

                <!-- <div class="col-12">
                    <div class="swiper-container community-home__swiper" [swiper]="communitySwiper.config"
                        [(index)]="communitySwiper.index">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide community-home__item" *ngFor="let item of communitySwiper.data">
                                <div class="row">
                                    <div class="lg- community-home__item-image">
                                        <img [src]="item.img" alt="" class="img-fluid">
                                    </div>
                                    <div class="col-8 community-home__item-content">
                                        <p [innerHTML]="item.description"></p>
                                        <h4 [innerHTML]="item.title">Juan Posada</h4>
                                        <p [innerHTML]="item.short">Presidente de la Junta Directiva del Embassy Club
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>-->

                <div class="col-12 community-home__swiper">
                    <div class="row">
                        <div class="col-lg-4 community-home__item" *ngFor="let item of communitySwiper.data">
                            <div class="row">
                                <div class="col-lg-4 col-md-7 col-12 community-home__item-image">
                                    <img [src]="item.img" alt="" class="img-fluid">
                                </div>
                                <div class="col-lg-8 col-md-7 col-12 community-home__item-content">
                                    <p [innerHTML]="item.description"></p>
                                    <h4 [innerHTML]="item.title">Juan Posada</h4>
                                    <p [innerHTML]="item.short">Presidente de la Junta Directiva del Embassy Club
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <img src="assets/img/community/path-bottom-blue.png" alt="" class="end-section">
</section>