import { Component, OnInit } from '@angular/core';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-section-faqs',
  templateUrl: './section-faqs.component.html',
  styleUrls: ['./section-faqs.component.scss']
})
export class SectionFaqsComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
