import { Component, OnInit } from '@angular/core';
import { AmplitudeService } from "../../../services/amplitude.service";
import { AmplitudeEvent } from "../../../interfaces/amplitude";

@Component({
  selector: 'app-download-home',
  templateUrl: './download-home.component.html',
  styleUrls: ['./download-home.component.sass']
})
export class DownloadHomeComponent implements OnInit {

  constructor(private amplitudeService: AmplitudeService) { }

  ngOnInit(): void {
  }

  sendEventToAmplitude(){
    this.amplitudeService.sendToAmplitude({
      event_type: AmplitudeEvent.demoRquested,
      user_id: ""
    })
  }
}
