<section class="home-video">
    <div class="container mb-5">
        <div class="row">
            <div class="col-lg-10 col-12 home-video__col" (click)="playVideo()">

                    <div class="d-sm-none d-md-block">
                      <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel" data-bs-interval="false">
                        <div class="carousel-inner">
                          <div class="carousel-item active text-center embed-responsive embed-responsive-16by9">
                            <iframe src="https://www.youtube.com/embed/kz-Jg7N7Csw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </div>
                          <div class="carousel-item text-center">
                            <div class="embed-responsive embed-responsive-16by9">
                              <iframe src="https://www.youtube.com/embed/knIsaxzmhM8" frameborder="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                          </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"  data-bs-slide="prev">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"  data-bs-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="visually-hidden">Next</span>
                        </button>
                      </div>
                    </div>
            </div>
        </div>
    </div>

    
    
    <svg _ngcontent-bsf-c42="" class="svg">
        <clipPath _ngcontent-bsf-c42="" id="my-clip-path" clipPathUnits="objectBoundingBox">
            <path _ngcontent-bsf-c42=""
            d="M0,0.067 C0.063,0.133,0.125,0.2,0.313,0.133 Q0.75,0,1,0.133 L1,1 C0.688,0.533,0.25,0.733,0,0.4">
        </path>
    </clipPath>
</svg>

    <div class="clip-path"></div>
</section>