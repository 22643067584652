
export enum MunilyTruckImageType {
  type1 = 'type1',
  type2 = 'type2',
  type3 = 'type3',
  type4 = 'type4',
  typeEmailWeb = 'typeEmailWeb',
  typeEmailMobile = 'typeEmailMobile',
}

export class FileUpload {
  size: number;
  filename: string;
  type: string;
  url: string;
  id: string;
  nameServer: string;
}

export class MunilyTruckImageConfig {
  file: FileUpload;
  type: MunilyTruckImageType;
  text: string;
}
export enum MunilyTruckStatus {
  active = 'active',
  inactive = 'inactive',
  deleted = 'deleted',
}

export enum typeInvitation {
  fastpass = 'fastpass',
  updateDataFastPass = 'updateDataFastPass'
}

export interface MunilyTrucks {
  name?: string | undefined;
  code?: string | undefined;
  status?: MunilyTruckStatus | undefined;
  description?: string;
  images?: MunilyTruckImageConfig[];
  categories?: string[];
  link?: string;
}

export interface MapperResponseMunilyTrucks {
  name: string;
  description: string;
  link: string;
  images: string;
}

export interface ResponseMunilyTrucks {
  data: MunilyTrucks[];
}

export interface SavingsParams {
  userId: string;
  userName: string;
  userEmail: string;
  address: string;
  userPhone: string;
  buildingId: string;
  latitudBuilding: string;
  longitudBuilding: string;
  categoryId?: string;
}

export interface ResponseCategoriesBuilding {
  data: List[];
}

export interface List {
  status: string;
  name: string;
  image: FileUpload;
}



export interface invitationParams {
  token: string;
  type: typeInvitation
}
