import { Component, OnInit, Directive, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  currentTransitionSpeed = 0;

  textSwiper = {
    index: 0,
    prevIndex: 0,
    config: {
      loop: true,
      initialSlide: 0,
      slidesPerView: 1,
      slidesPerColumn: 1,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      effect: 'fade',
      direction: 'horizontal',
      fadeEffect: { crossFade: true },
      speed: 1000,
      simulateTouch:false
    }
  };

  imgSwiper = {
    config: {
      direction: 'horizontal',
      slidesPerView: 1,
      speed: 1000,
      simulateTouch:false,
      effect: 'fade',
      fadeEffect: { crossFade: true },
    }
  };

  constructor(private render: Renderer2) { }

  ngOnInit() {
    // let that = this;

    // setInterval(function(){
    //   that.textSwiper.prevIndex = that.textSwiper.index;

    //   if(that.textSwiper.index === 3){
    //     that.textSwiper.index = 0;
    //   }else{
    //     that.textSwiper.index = that.textSwiper.index + 1;
    //   }
    // }, 3000);
  }

  slideChanged(){
    const that = this;

    document.querySelectorAll('.section__swiper .section__paragrahp').forEach(function(el: HTMLElement, index){
      if(index == that.textSwiper.index){
        that.render.addClass(el, 'slideOutUp');
        that.render.addClass(el, 'animated');
      }else if (index == (that.textSwiper.index + 1)){
        that.render.addClass(el, 'slideInUp');
        that.render.addClass(el, 'animated');
      }else{
        that.render.removeClass(el, 'slideOutUp');
        that.render.removeClass(el, 'slideInUp');
        that.render.removeClass(el, 'animated');
      }
    });

    this.textSwiper.prevIndex = this.textSwiper.index;
  }

  slideImgChanged(){
    const that = this;

    document.querySelectorAll('.section__img-swiper .img-fluid').forEach(function(el: HTMLElement, index){
      if(index == that.textSwiper.index){
        console.log(el);
        that.render.addClass(el, 'slideInRight');
        that.render.addClass(el, 'animated');
      }else if (index == (that.textSwiper.index - 1)){
        that.render.addClass(el, 'slideOutRight');
        that.render.addClass(el, 'animated');
      }else{
        that.render.removeClass(el, 'slideOutRight');
        that.render.removeClass(el, 'slideInRight');
        that.render.removeClass(el, 'animated');
      }
    });
  }
}
