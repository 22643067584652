import { Component, OnInit, Renderer2 } from '@angular/core';
import { AmplitudeService } from "../../../services/amplitude.service";
import { AmplitudeEvent } from "../../../interfaces/amplitude";
import { DataDogService, callsType } from 'src/app/services/data-dog.service';

@Component({
  selector: 'app-hero-home',
  templateUrl: './hero-home.component.html',
  styleUrls: ['./hero-home.component.sass']
})
export class HeroHomeComponent implements OnInit {

  textSwiper = {
    index: 0,
    prevIndex: 0,
    config: {
      loop: true,
      initialSlide: 0,
      slidesPerView: 1,
      slidesPerColumn: 1,
      // autoplay: {
      //   delay: 3000,
      //   disableOnInteraction: false
      // },
      effect: 'fade',
      direction: 'horizontal',
      fadeEffect: { crossFade: true },
      speed: 1000,
      simulateTouch: false
    }
  };

  imgSwiper = {
    index: 0,
    config: {
      direction: 'horizontal',
      slidesPerView: 1,
      speed: 1000,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      simulateTouch: false,
      effect: 'fade',
      fadeEffect: { crossFade: true },
    }
  };

  constructor(private render: Renderer2,
    private amplitudeService: AmplitudeService,
    private dataDogService: DataDogService) { }

  ngOnInit(): void {
  }

  slideChanged() {
    const that = this;

    document.querySelectorAll('.home-hero__swiper .home-hero__paragraph').forEach(function (el: HTMLElement, index) {
      if (index == that.textSwiper.index) {
        that.render.addClass(el, 'animate__slideOutUp');
        that.render.addClass(el, 'animate__animated');
      } else if (index == (that.textSwiper.index + 1)) {
        that.render.addClass(el, 'animate__slideInUp');
        that.render.addClass(el, 'animate__animated');
      } else {
        that.render.removeClass(el, 'animate__slideOutUp');
        that.render.removeClass(el, 'animate__slideInUp');
        that.render.removeClass(el, 'animate__animated');
      }
    });

    this.textSwiper.prevIndex = this.textSwiper.index;
  }

  slideImgChanged() {
    const that = this;

    // document.querySelectorAll('.home-hero__img-swiper .img-fluid').forEach(function (el: HTMLElement, index) {
    //   if (index == that.textSwiper.index) {
    //     console.log(el);
    //     that.render.addClass(el, 'slideInRight');
    //     that.render.addClass(el, 'animated');
    //   } else if (index == (that.textSwiper.index - 1)) {
    //     that.render.addClass(el, 'slideOutRight');
    //     that.render.addClass(el, 'animated');
    //   } else {
    //     that.render.removeClass(el, 'slideOutRight');
    //     that.render.removeClass(el, 'slideInRight');
    //     that.render.removeClass(el, 'animated');
    //   }
    // });
  }

  sendEventToAmplitude(){
    this.amplitudeService.sendToAmplitude({
      event_type: AmplitudeEvent.demoRquested,
      user_id: ""
    })
  }

  dataDogEvent(key: string, callType: string, value?: any){
    this.dataDogService.sendEventToDataDog(key, callsType[callType], value);
  }
}
