<section class="logos">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-12 logos__heading">
                <h3>Más de 160 residencias utilizan Munily para la gestión de comunidades </h3>
            </div>

            <div class="col-12">
                <div class="swiper-container logos__swiper px-4" [swiper]="logosSwiper.config"
                    [(index)]="logosSwiper.index">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide logos__item" *ngFor="let item of logosSwiper.data">
                            <div class="row">
                                <img [src]="item.img" alt="" class="img-fluid">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="swiper-container logos__swiper px-4" [swiper]="logosSwiper.config"
                    [(index)]="logosSwiper.index">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide logos__item" *ngFor="let item of logosSwiper.data2">
                            <div class="row">
                                <img [src]="item.img" alt="" class="img-fluid">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>