import { Component, Input, OnInit, AfterContentChecked, DoCheck, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import anime from 'animejs/lib/anime.es.js';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements AfterContentChecked {

  activeHeader = false
  notHeader: boolean = true;
  constructor(
    private router: Router,
  ) { }

  counter: number = 0
  
  ngAfterContentChecked(): void {
    if ( this.router.url === '/contact-us') {
      this.notHeader = false;
    } else if ( this.router.url === '/' ) {
      this.hiddenHeader()
    }
      else {
      this.notHeader = true ;
    }

  }

  hiddenHeader() {
    this.counter++
      if (this.counter > 2) {
        this.notHeader = false;
      }
  }

  toggleMenu(): void {
    if (this.activeHeader) {
      anime({
        top: 12.5,
        targets: '.main-header__button-line:first-child',
        rotate: '0deg',
        easing: 'easeOutInQuad',
        duration: 300
      });

      anime({
        top: 20,
        targets: '.main-header__button-line:nth-child(2)',
        opacity: 1,
        easing: 'easeOutInQuad',
        duration: 300
      });

      anime({
        top: 27.5,
        targets: '.main-header__button-line:last-child',
        rotate: '0deg',
        easing: 'easeOutInQuad',
        duration: 300
      });

      anime({
        right: -330,
        targets: '.main-header__menu',
        easing: 'linear',
        duration: 400
      });
    } else {
      anime({
        top: 20,
        targets: '.main-header__button-line:first-child',
        rotate: '45deg',
        easing: 'easeOutInQuad',
        duration: 300
      });

      anime({
        top: 20,
        targets: '.main-header__button-line:nth-child(2)',
        opacity: 0,
        easing: 'easeOutInQuad',
        duration: 300
      });

      anime({
        top: 20,
        targets: '.main-header__button-line:last-child',
        rotate: '-45deg',
        easing: 'easeOutInQuad',
        duration: 300
      });

      anime({
        right: 0,
        targets: '.main-header__menu',
        easing: 'linear',
        duration: 400
      });
    }

    this.activeHeader = !this.activeHeader;
  }
}
