import { Component, OnInit } from '@angular/core';
import { TyCTypes } from '../termsv2/termsv2.component';
import { TyCCountry, TyCDTOResponse } from '../interfaces/tycInterface';
import { PublicService } from '../services/public.service';
import { ActivatedRoute } from '@angular/router';

const CountryByDefault = 'co';

@Component({
  selector: 'app-terms-munily-pay',
  templateUrl: './terms-munily-pay.component.html',
  styleUrls: ['./terms-munily-pay.component.scss']
})
export class TermsMunilyPayComponent implements OnInit {
  countries: TyCCountry[] = [];
  countrySelected: string = 'Panamá';
  typeTyCSelected: TyCTypes = TyCTypes.tyc;
  mobileMode = false;
  tycTypes = [
    {
      display: 'Términos y condiciones billetera digital MUNILYPAY',
      value: TyCTypes.munilyPay
    }
  ];
  countryQuery: string;
  hasQuery = false;


  constructor(private publicService: PublicService, private activatedRoute: ActivatedRoute) {
    const countryQuery = this.activatedRoute.snapshot.queryParams.country;
    if (countryQuery) this.countryQuery = countryQuery;
    const typeTyCSelected = this.activatedRoute.snapshot.queryParams.type;
    if (typeTyCSelected) this.typeTyCSelected = typeTyCSelected;
    if ( countryQuery || typeTyCSelected ) this.hasQuery = true

  }

  ngOnInit(): void {
    this.publicService.getTyCMunilyPay().subscribe(
      ({ data }: TyCDTOResponse) => {
        this.countries = data.countries;
        if (this.hasQuery) {
          this.setCountryAndTyCTypeByQuery();
        } else {
          this.setTyCCountryByDefault(data.countries);
        }
      },
      (err) => console.error(err)
    );
  }

  setCountryAndTyCTypeByQuery() {
    const country = this.countries.find((e) => e.isoCode == this.countryQuery);
    this.chooseTyCByCountry(country)
  }

  setTyCCountryByDefault(countries: TyCCountry[]) {
    const country = countries.find((e) => e.isoCode == CountryByDefault);
    this.countrySelected = country.name;
    this.renderTyC(country.tyc.es);
  }

  chooseTyCByCountry(country?: TyCCountry) {
    if (!country) {
      country = this.countries.find((e) => e.name == this.countrySelected);
    } else {
      this.countrySelected = country.name;
    }

    if (this.typeTyCSelected == TyCTypes.privacity) {
      this.renderTyC(country.privacity.es);
    }
    if (this.typeTyCSelected == TyCTypes.tyc) {
      this.renderTyC(country.tyc.es);
    }
  }

  toogleTyCType(tycType: TyCTypes) {
    this.typeTyCSelected = tycType;
    this.chooseTyCByCountry();
  }

  renderTyC(text: string) {
    let div: HTMLDivElement = document.querySelector('.containerTyC');
    div.innerHTML = text;
    div.style.width = '55vw';
    this.setStylesToMobile();
  }

  setStylesToMobile() {
    if (window.screen.width < 700) {
      this.mobileMode = true;
      let div: HTMLDivElement = document.querySelector('.contenedor');
      div.style.display = 'block';
      div.style.padding = '0px';

      let divTyc: HTMLDivElement = document.querySelector('.containerTyC');
      divTyc.style.width = '80%';
      divTyc.style.marginLeft = '10%';

      let rightSide: HTMLDivElement = document.querySelector('.rightSide');
      rightSide.append(document.createElement('hr'));
      rightSide.style.width = '100%';
      rightSide.style.height = '20vh';
      rightSide.style.margin = '0';
      rightSide.style.marginBottom = '20px';
    }

    window.scroll({ top: 100 });
  }


}
