import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SectionOneComponent } from './section-one/section-one.component';
import { SectionTwoComponent } from './section-two/section-two.component';
import { SectionThreeComponent } from './section-three/section-three.component';
import { SectionFourComponent } from './section-four/section-four.component';
import { TestimonyComponent } from './testimony/testimony.component';
import { SectionFiveComponent } from './section-five/section-five.component';
import { SectionContactComponent } from './section-contact/section-contact.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
/* import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2'; */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SectionFaqsComponent } from './section-faqs/section-faqs.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	declarations: [
		SectionOneComponent,
		SectionTwoComponent,
		SectionThreeComponent,
		SectionFourComponent,
		TestimonyComponent,
		SectionFiveComponent,
		SectionContactComponent,
		SectionFaqsComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		SwiperModule,
		/* SweetAlert2Module, */
		BrowserAnimationsModule,
		NgbModule
	],
	exports: [
		SectionOneComponent,
		SectionTwoComponent,
		SectionThreeComponent,
		SectionFourComponent,
		TestimonyComponent,
		SectionFiveComponent,
		SectionContactComponent,
		SectionFaqsComponent
	]
})

export class LayoutModule { }
