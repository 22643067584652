<svg class="svg">
    <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
        <path d="M0.667,0 L0,0 L0,1 L1,1 C0.933,0.417,0.8,0.25,0.667,0"></path>
    </clipPath>
</svg>
<svg class="svg">
    <clipPath id="my-clip-path2" clipPathUnits="objectBoundingBox">
        <path d="M0,0.167 C0.438,0.167,0.563,0.167,1,0.667 L1,1 L0,1 L0,0.167"></path>
    </clipPath>
</svg>

<div class="clipped-contact">
    <div class="row m-0">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
            <div class="contact__content">
                <img src="../../../assets/svg/Grupo 11.svg" class="contact__img">
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
            <p class="contact__parraf">Solicita una demo y nos pondremos en contacto contigo</p>
            <p class="contact__parraf">¿Qué verás en la demo?</p>
            <ul class="contact__list">
                <li>Cómo tener una mayor seguridad en tu residencia.</li>
                <li>Cómo tener una comunicación efectiva entre guardias de seguridad, residentes y responsables administrativos.</li>
                <li>Cómo gestionar actividades administrativas.</li>
            </ul>
        </div>
    </div>
</div>