import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/services/main.service';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment.dev';
import { CrmFormInterface, errorValidInput, campoNameHubspot } from '../interfaces/CrmInterface';
import { JsonLDService } from '../services/json-ld.service';
import { AmplitudeService } from ".././services/amplitude.service";
import { AmplitudeEvent, formSentStructure } from '../interfaces/amplitude';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.sass'],
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  newFormPage: FormGroup;
  crmFormInterface: CrmFormInterface;

  countrySize: string = "1000";
  countries: any[] = [
    "Argentina",
    "Bolivia",
    "Brasil",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Ecuador",
    "El Salvador",
    "Guatemala",
    "Haití",
    "Honduras",
    "México",
    "Nicaragua",
    "Panamá",
    "Paraguay",
    "Perú",
    "Puerto Rico",
    "República Dominicana",
    "Uruguay",
    "Venezuela",
    "Otro",
  ];
  cities: string[] = [
    "Armenia",
    "Barrancabermeja",
    "Barranquilla",
    "Bogotá",
    "Bucaramanga",
    "Buga",
    "Cali",
    "Cartagena",
    "Chía",
    "Cúcuta",
    "Duitama",
    "Florencia",
    "Fusagasugá",
    "Girardot",
    "Honda",
    "Ibagué",
    "Ipiales",
    "Jamundí",
    "Leticia",
    "Manizales",
    "Mariquita",
    "Medellín",
    "Mompox",
    "Montería",
    "Murillo",
    "Neiva",
    "Pamplona",
    "Pasto",
    "Pereira",
    "Popayán",
    "Riohacha",
    "San Andrés y Providencia",
    "San Antero",
    "Santa Marta",
    "Santafé de Antioquia",
    "Sevilla",
    "Sincelejo",
    "Sogamoso",
    "Tabio",
    "Tocancipá",
    "Tolú",
    "Tuluá",
    "Tumaco",
    "Tunja",
    "Valledupar",
    "Villavicencio",
    "Yopal",
    "Zipaquirá"
  ]
  levelServiceNeedOpts:string[] = [
    "¡Muy urgente!",
    "Podría resolver VARIAS necesidades de mi comunidad.",
    "Podría resolver UNA necesidad puntual de mi comunidad residencial"
  ]
  experienceDoYouHaveOpts:string[] = [
    "Estoy en proceso de formación.",
    "Profesional con +10 años de experiencia en PH",
    "Maestro experto con más de 20 años en PH"
  ]
  isSelect = false;

  objecttest = {
    [formSentStructure.country] : "",
    [formSentStructure.adminExperience]: "",
    [formSentStructure.city] : "",
    [formSentStructure.serviceNeed]: ""
  }

  messageSuccess: string = 'Su formulario ha sido enviado con éxito';
  messageError: string = 'Por favor valide su formulario';

  constructor(
    private fb: FormBuilder,
    private jsonLd: JsonLDService,
    private mainService: MainService,
    private toastr: ToastrService,
    private amplitudeService: AmplitudeService
  ) {

    this.contactForm = this.fb.group({
      country: this.fb.control(null, [Validators.required, Validators.minLength(3)]),
      city: this.fb.control(null),
      name: this.fb.control(null, [Validators.required, Validators.minLength(3), Validators.maxLength(200)]),
      position: this.fb.control(null, [Validators.required, Validators.maxLength(200)]),
      email: this.fb.control(null, [Validators.email, Validators.required]),
      phone: this.fb.control(null, [Validators.required, Validators.minLength(9)]),
      serviceNeed: this.fb.control(null, [Validators.required]),
      experienceDoYouHave: this.fb.control(null, [Validators.required])
    });
  }

  ngOnInit(): void {
    // this.jsonLd.insertSchema(this.jsonLd.contactSchema());
    this.initFormPage();
    this.testClick();
  }

  testClick(){
    this.newFormPage.get('city').disable();
  }

  ngOnDestroy(): void {
    this.jsonLd.removeStructuredData();
  }

  initFormPage(data?) {
    this.newFormPage = this.fb.group({
      country: this.fb.control(null, [Validators.required, Validators.minLength(3)]),
      city: this.fb.control(null),
      name: this.fb.control(null, [Validators.required, Validators.minLength(3), Validators.maxLength(200)]),
      position: this.fb.control(null, [Validators.required, Validators.maxLength(200)]),
      email: this.fb.control(null, [Validators.email, Validators.required]),
      phone: this.fb.control(null, [Validators.required, Validators.minLength(9)]),
      serviceNeed: this.fb.control(null, [Validators.required]),
      experienceDoYouHave: this.fb.control(null, [Validators.required])
    });
  }

  setValueChange(e){
    const country:string = e.target.value;
    if(country.toLowerCase() === 'colombia'){
      this.newFormPage.get('city').enable();
      this.newFormPage.controls['city'].setValidators(Validators.required);
      this.newFormPage.get('city').updateValueAndValidity();
    } else {
      this.newFormPage.get('city').disable();
      this.newFormPage.controls['city'].clearValidators();
      this.newFormPage.get('city').updateValueAndValidity();
    }
  }

  valid(){
    let valid: boolean = true;
    for (const control in this.newFormPage.controls) {
      if (this.newFormPage.controls.hasOwnProperty(control)) {
        this.newFormPage.controls[control].markAsTouched();
        if(this.newFormPage.controls[control].errors){
          this.toastr.error('El campo ' + errorValidInput[control] + ' es requerido');
          valid = false;
        }
      }
    }
    return valid;
  }

  senToCRM() {
    var time = new Date().getTime().toString();

    if(!this.valid()) return;
    const form = this.newFormPage.value;
    let country = { name: campoNameHubspot.country, value:form.country };
    let city = { name:campoNameHubspot.city, value: form.city ? form.city : '' };
    let name = { name:campoNameHubspot.name, value:form.name };
    let position ={ name:campoNameHubspot.jobTitle, value:form.position };
    let email = { name:campoNameHubspot.email, value:form.email };
    let phone = { name:campoNameHubspot.phone, value:form.phone };
    let levelServiceNeed = { name:campoNameHubspot.serviceNeed, value: form.serviceNeed };
    let experienceDoYouHave = { name:campoNameHubspot.experience, value:form.experienceDoYouHave }

    this.crmFormInterface = {
      submittedAt: time,
      fields: [country, city, name, position, email, phone, levelServiceNeed, experienceDoYouHave],
      context: {
        pageUri: 'munily.com',
        pageName: 'Munily',
      },
    };

    this.objecttest[formSentStructure.country] = country.value;
    this.objecttest[formSentStructure.adminExperience] = experienceDoYouHave.value;
    this.objecttest[formSentStructure.city] = city.value;
    this.objecttest[formSentStructure.serviceNeed] = levelServiceNeed.value;

    this.mainService
      .sendFormToCRM(this.crmFormInterface, environment.formIdPaginaPrincipal)
      .subscribe(
        (res) => {
          this.newFormPage.reset();
          this.sendEventToAmplitude();
          this.isSelect = true;
          Swal.fire(
            '¡Bien!',
            'Recibimos tu solicitud. Nos contactaremos contigo lo más pronto posible.',
            'success'
          );
        },
        (err) => {
          this.toastr.error(this.messageError);
        }
      );
  }

  sendEventToAmplitude(){
    this.amplitudeService.sendToAmplitude({
      event_type: AmplitudeEvent.formSent,
      user_id: "",
      event_properties: {
        [formSentStructure.country] : this.objecttest[formSentStructure.country],
        [formSentStructure.adminExperience]: this.objecttest[formSentStructure.adminExperience],
        [formSentStructure.city]: this.objecttest[formSentStructure.city],
        [formSentStructure.serviceNeed]: this.objecttest[formSentStructure.serviceNeed]
      }
    })
  }
}
