import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logos-carousel-home',
  templateUrl: './logos-carousel-home.component.html',
  styleUrls: ['./logos-carousel-home.component.sass']
})
export class LogosCarouselHomeComponent implements OnInit {

  logosSwiper = {
    index: 0,
    config: {
      speed: 1000,
      autoplay: true,
      initialSlide: 0,
      slidesPerView: 6,
      spaceBetween: 60,
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        576: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 40
        },
        991: {
          slidesPerView: 6,
          spaceBetween: 60
        }
      }
    },
    data: [
      { img: 'assets/img/logos/1.png' },
      { img: 'assets/img/logos/2.png' },
      { img: 'assets/img/logos/3-min.jpg' },
      { img: 'assets/img/logos/4.png' },
      { img: 'assets/img/logos/5.png' },
      { img: 'assets/img/logos/6-min.png' },
      { img: 'assets/img/logos/7-min.png' },
      { img: 'assets/img/logos/8.png' },
      { img: 'assets/img/logos/9.png' },
      { img: 'assets/img/logos/10.png' },
      { img: 'assets/img/logos/11.png' },
      { img: 'assets/img/logos/12-min.png' },
    ],
    data2: [
      // { img: 'assets/img/logos/13.png' },
      { img: 'assets/img/logos/14.png' },
      { img: 'assets/img/logos/15-min.jpeg' },
      { img: 'assets/img/logos/16.png' },
      { img: 'assets/img/logos/17-min.jpeg' },
      { img: 'assets/img/logos/18.jpg' },
      { img: 'assets/img/logos/19-min.jpg' },
      { img: 'assets/img/logos/20-min.jpg' },
      { img: 'assets/img/logos/21.png' },
      { img: 'assets/img/logos/22.png' },
      { img: 'assets/img/logos/23.jpg' },
      { img: 'assets/img/logos/24-min.jpg' },
    ],
  };

  constructor() { }

  ngOnInit(): void {
  }

}
