<nav class="navbar navbar-expand-lg navbar-light fixed-top">
    <div class="navbar-color__home"></div>
    <a class="navbar-brand navbar__logo" href="javascript:void(0);" [routerLink]="['/']" routerLinkActive="active" ><img src="../../../assets/img/munily-logo-2.png" alt="Munily" height="64px"></a>
    <button class="navbar-toggler border-0" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <ul class="navbar-nav ml-auto navbar__links">
            <!--<li class="nav-item">
                <a class="nav-link ml-5 mr-4 navbar__ulinks" href="/">Home</a>
            </li>
            <li class="nav-item">
                <a class="nav-link ml-5 mr-4 navbar__ulinks" href="#">Funcionalidades</a>
            </li>
            <li class="nav-item">
                <a class="nav-link ml-5 mr-4 navbar__ulinks" href="#">Nosotros</a>
            </li>
            <li class="nav-item">                
                <a class="nav-link ml-5 mr-5 navbar__ulinks">Contactos</a>
            </li>-->
            <li class="nav-item dropdown">
                <a class="btn btn-default dropdown-toggle" href="#" id="navbarDropdown" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Es
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" href="#">En</a>
                </div>
            </li>
            <li class="nav-item">
                <a class="btn btn-info navbar__btn-munily ml-5" href="https://dashboard.munily.com/">Inicia Sesión</a>
            </li>
        </ul>
    </div>
</nav>