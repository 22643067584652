<section class="download">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-12 download__content">
                <h2>Únete a Munily</h2>
                <p>Comunidades seguras y conectadas</p>
            </div>
            <div class="col-lg-3 col-12 download__buttons">
                <a href="https://play.google.com/store/apps/details?id=com.munily.movil&hl=es_PA" rel="noreferrer"
                    target="_blank"><img src="assets/img/download/android.png" alt="Google Play" class="img-fluid"></a>
                <a href="https://apps.apple.com/pa/app/munily/id1434349453" rel="noreferrer" target="_blank"><img
                        src="assets/img/download/apple.svg" alt="App Store" class="img-fluid"></a>

                <!-- <a [routerLink]="['/contact-us']" routerLinkActive="router-link-active" class="blue-btn">Solicitar una
                    demo</a> -->
                    <p style="font-size: 15px; padding-top: 15px;">Solicite una demo con nuestros expertos</p>
            </div>
            <div class="col-lg-4 col-12 download__phone">
                <img src="assets/img/download/iphone.png" alt="" class="img-fluid">
            </div>
        </div>
    </div>
</section>

<section class="pre-footer">
    <div class="container">
        <div class="row">
            <div class="d-none d-md-flex align-items-center col-md-8 pre-footer__text">
                <p class="mt-2" style="color: #4A4A4A; font-size: 25px; font-weight: 600;">Solicite una demo con nuestros expertos</p>
            </div>
            <div class="col-md-4 col-12 pre-footer__button py-4 text-center">
                <a [routerLink]="['/contact-us']" routerLinkActive="router-link-active" aria-label="Solicitar una demo"
                    class="blue-btn" (click)="sendEventToAmplitude()">Solicitar una demo</a>
                </div>
            </div>
        </div>
    </section>
