<div class="testimony__container">
    <div class="row justify-content-center">
        <div class="col-lg-8 col-12 testimony__heading testimony__mb">
            <h1 class="section__title mt-4 mb-3">Vive en <span class="color">comunidad</span> como nunca antes</h1>
            <h3 class="section__title">Trabajamos para crear comunidades seguras y conectadas empezando residencia por residencia.</h3>
        </div>
    </div>
    <div class="row m-0">
        <div class="col-lg-4">
            <div class="row">
                <div class="col-lg-4 col-12">
                    <img src="../../../assets/img/photo-1.png" class="img-fluid testimony__img mb-5" width="153px">
                </div>
                <div class="col-lg-8 col-12 testimony__mb-mobile pl-md-0">
                    <p class="testimony__text">Cuando un vecino me recomendó Munily, no dude que sería de gran ayuda. Ha facilitado la vida a los residentes y a la administración, manteniendo la seguridad y el buen servicio como premisa.</p>
                    <br>
                    <h4 class="section__title testimony__title">Juan Posada</h4>
                    <h4 class="section__title testimony__subtitle">Presidente de la Junta Directiva de Embassy Club</h4>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="row">
                <div class="col-lg-4 col-12">
                    <img src="../../../assets/img/photo-2.png" class="img-fluid testimony__img mb-5">
                </div>
                <div class="col-lg-8 col-12 testimony__mb-mobile pl-md-0">
                    <p class="testimony__text">Munily es rápido, fácil y sencillo. Los residentes buscan: seguridad, comunicación con guardias y saber quién entra a su hogar, Munily ofrece las tres opciones. El sistema me encanta.</p>
                    <br>
                    <h4 class="section__title testimony__title">Manuel Castellano</h4>
                    <h4 class="section__title testimony__subtitle">Guardia de seguridad</h4>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="row">
                <div class="col-lg-4 col-12">
                    <img src="../../../assets/img/photo-3.png" class="img-fluid testimony__img mb-5 rounded-pill" width="153px">
                </div>
                <div class="col-lg-8 col-12 testimony__mb-mobile pl-md-0">
                    <p class="testimony__text">Me parece bastante útil, Munily permite tener el registro de todos los movimientos del edificio e interactuar con el co-propietario para atender sus solicitudes de forma rápida y segura.</p>
                    <br>
                    <h4 class="section__title testimony__title">Walther Pérez</h4>
                    <h4 class="section__title testimony__subtitle">Administrador del P.H. Vita</h4>
                </div>
            </div>
        </div>
    </div>
</div>