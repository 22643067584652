import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { datadogRum } from '@datadog/browser-rum';
import { datadogConfig } from 'datadog.config';
// const { version } = require('../version-config');


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


datadogRum.init({
  applicationId: datadogConfig.applicationId,
  clientToken: datadogConfig.clientToken,
  site: datadogConfig.site,
  service: datadogConfig.service,
  env: environment.envDeployed,
  // Specify a version number to identify the deployed version of your application in Datadog 
  version: '2.48.3',
  sessionSampleRate: 5,
  sessionReplaySampleRate: 5,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel:'mask-user-input'
});
    
datadogRum.startSessionReplayRecording();
