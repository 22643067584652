export interface TyCDTOResponse {
  data: TyCData;
}

export interface TyCData {
  createdAt: string;
  current: boolean;
  id: string;
  updatedAt: string;
  version: string;
  countries: TyCCountry[];
}

export interface TyCCountry {
  id: string;
  isoCode: string;
  name: string;
  privacity: {
    en: string;
    es: string;
  };
  tyc: {
    en: string;
    es: string;
  };
}

export enum LanguageCode{
  english = "en",
  spanish = "es"
}

export enum CountryIsoCode{
  co = "co",  //Colombia
  pa = "pa"   //Panama
}

export enum Country{
  Colombia = "Colombia",
  Panama = "Panama"
}

export enum QR_TyC_Url{
  colombia_es = "https://munily.com/terms/frequent-access/data-treatment/",
  colombia_en = "https://munily.com/en/terms/terms-and-conditions-for-the-use-of-single-sign-on-and-frequent-access-qr-codes/",
  panama_es = "https://munily.com/terms/frequent-access/data-treatment-panama/",
  panama_en = "https://munily.com/en/terms/terms-and-conditions-for-the-use-of-single-sign-on-and-frequent-access-qr-codes-panama/"
}

export enum PrivacyUrlsByCountryAndLanguage{
  pa_es = "https://munily.com/terms/politica-de-privacidad-y-tratamiento-de-datos-personales-panama/",
  pa_en = "https://munily.com/en/privacy-policy-data-treatment-panama/",
  co_es = "https://munily.com/terms/politica-de-privacidad-y-tratamiento-de-datos-personales/",
  co_en = "https://munily.com/en/privacy-policy-data-treatment/"
}

export enum TCQRUrlsByCountryAndLanguage{
  pa_es = "https://munily.com/terms/frequent-access/data-treatment-panama/",
  pa_en = "https://munily.com/en/terms/terms-and-conditions-for-the-use-of-single-sign-on-and-frequent-access-qr-codes-panama/",
  co_es = "https://munily.com/terms/frequent-access/data-treatment/",
  co_en = "https://munily.com/en/terms/terms-and-conditions-for-the-use-of-single-sign-on-and-frequent-access-qr-codes/"
}

export const LanguageByDefault = LanguageCode.english;
export const IsoCodeByDefault = CountryIsoCode.pa;
export const IsoByDefault = `${IsoCodeByDefault}_${LanguageByDefault}`;
