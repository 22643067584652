<div class="section-three__container">
    <div class="row align-items-center section-three__mb">
        <div class="col-lg-5">
            <h2 class="section__title mt-4 mb-4">Administración 4.0</h2>
            <ul class="section-three__list">
                <li class="section-three__paragrahp">
                    Administración remota.
                </li>
                <li class="section-three__paragrahp">
                    Votación en tiempo real.
                </li>
                <li class="section-three__paragrahp">
                    Monitoreo de guardias de seguridad.
                </li>
                <li class="section-three__paragrahp">
                    Gestión de información de residentes.
                </li>
                <li class="section-three__paragrahp">
                    Gestión de áreas comunes y sus reservas.
                </li>
            </ul>
        </div>
        <div class="col-lg-7">
            <img src="../../../assets/img/administracion.png" class="img-fluid">
        </div>
    </div>
    <div class="row align-items-center section-three__mb">
        <div class="col-lg-5 section-three__desktop">
            <img src="../../../assets/img/comunicacion.png" class="img-fluid">
        </div>
        <div class="col-lg-6 offset-lg-1">
            <h2 class="section__title mt-4 mb-4">Comunicación 4.0</h2>
            <ul class="section-three__list">
                <li class="section-three__paragrahp">
                    Envíos de comunicados con indicadores de recibidos y vistos.
                </li>
                <li class="section-three__paragrahp">
                    Un comunicado dos canales: app móvil y correo a la vez.
                </li>
                <li class="section-three__paragrahp">
                    Actualización de las actividades de la comunidad.
                </li>
                <li class="section-three__paragrahp">
                    Comunicación con guardias 24/7.
                </li>
                <li class="section-three__paragrahp">
                    Red de vecinos para la seguridad y comunicación comunitaria.
                </li>
            </ul>
        </div>
        <div class="col-lg-6 section-three__mobile">
            <img src="../../../assets/img/comunicacion.png" class="img-fluid">
        </div>
    </div>
    <div class="row align-items-center">
        <div class="col-lg-6">
            <h2 class="section__title mt-4 mb-4">Seguridad 4.0</h2>
            <ul class="section-three__list">
                <li class="section-three__paragrahp">
                    Bitácora de control de acceso en la nube.
                </li>
                <li class="section-three__paragrahp">
                    Escaneo automático de documentos con fotos.
                </li>
                <li class="section-three__paragrahp">
                    Registro de personas y accesos frecuentes.
                </li>
                <li class="section-three__paragrahp">
                    Intercom Virtual.
                </li>
                <li class="section-three__paragrahp">
                    Aprobar o denegar acceso de visitantes.
                </li>
            </ul>
        </div>
        <div class="col-lg-5 offset-lg-1">
            <img src="../../../assets/img/seguridad.png" class="img-fluid">
        </div>
    </div>
</div>
