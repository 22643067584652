<app-hero-home></app-hero-home>

<app-video-home></app-video-home>

<app-benefits></app-benefits>

<app-community-home></app-community-home>

<app-logos-carousel-home></app-logos-carousel-home>

<app-faqs-home class="faqs-section"></app-faqs-home>

<app-download-home></app-download-home>