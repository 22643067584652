import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MainService } from "src/app/services/main.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-form-contact",
  templateUrl: "./form-contact.component.html",
  styleUrls: ["./form-contact.component.scss"],
})
export class FormContactComponent implements OnInit {
  contactForm: FormGroup;

  apiData = {
    building: {
      name: "NA",
      address: "NA",
      city: "5c4e83fea5076d47d2ab0d62",
      country: "5c4e83fea5076d47d2ab0d62",
      phone: "0",
      comment: "",
    },
    user: {
      name: "",
      lastname: "",
      email: "",
      dni: "",
      phone: "",
      topic: "",
      terms: true,
    },
    roles: ["admin"],
    plan: null,
    status: "statusTempBuilding-waiting",
  };

  constructor(private mainService: MainService, private fb: FormBuilder) {
    this.contactForm = this.fb.group({
      name: this.fb.control(null, [
        Validators.required,
        Validators.minLength(3),
      ]),
      lastname: this.fb.control(null, [
        Validators.required,
        Validators.minLength(3),
      ]),
      phone: this.fb.control(null, [
        Validators.required,
        Validators.minLength(9),
      ]),
      email: this.fb.control(null, [Validators.email, Validators.required]),
      comment: this.fb.control(null, [Validators.minLength(5)]),
    });
  }

  ngOnInit() {}

  sendTestPetition() {
    console.log("entro aqui");
    this.apiData.building.comment = this.contactForm.value.comment;
    this.apiData.user.phone = this.contactForm.value.phone;
    this.apiData.user.name = this.contactForm.value.name;
    this.apiData.user.lastname = this.contactForm.value.lastname;
    this.apiData.user.email = this.contactForm.value.email;

    this.mainService.freeTestApp(this.apiData).then(() => {
      this.contactForm.reset();
      Swal.fire(
        "¡Bien!",
        "Recibimos tu solicitud. Nos contactaremos contigo lo más pronto posible.",
        "success"
      );
    });
  }
}
