import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-five',
  templateUrl: './section-five.component.html',
  styleUrls: ['./section-five.component.scss']
})
export class SectionFiveComponent implements OnInit {
  brandsSwiper = {
    index: 0,
    config: {
      direction: 'horizontal',
      loop: true,
      speed: 1000,
      // pagination: {
      //   el: '.swiper-pagination',
      //   type: 'bullets',
      //   clickable: true
      // },
      autoplay: {
        delay: 2000,
      },
      breakpoints: {
        // when window width is >= 768px
        768: {
          slidesPerView: 4,
        },
        // when window width is > 0px
        0: {
          slidesPerView: 1,
        },
      },
    },
  }

  constructor() { }

  ngOnInit() {
  }

}
