<div class="section-five__container">
  <span class="section-five__title mt-4 mb-4">
    Soñamos con que AMERICA sea el continente más conectado, seguro y feliz del
    mundo.
  </span>
</div>
<div class="section-five__container-grid">
  <div
    class="swiper-container section-five__swiper"
    [swiper]="brandsSwiper.config"
    [(index)]="brandsSwiper.index"
  >
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <img
          src="../../../assets/img/logos/logo-1.jpg"
          class="img-fluid section-five__logo"
          width="364px"
        />
      </div>
      <div class="swiper-slide">
        <img
          src="../../../assets/img/logos/logo-2.png"
          class="img-fluid section-five__logo"
          width="364px"
        />
      </div>
      <div class="swiper-slide">
        <img
          src="../../../assets/img/logos/logo-3.png"
          class="img-fluid section-five__logo"
          width="364px"
        />
      </div>
      <div class="swiper-slide">
        <img
          src="../../../assets/img/logos/logo-4.png"
          class="img-fluid section-five__logo"
          width="364px"
        />
      </div>
      <div class="swiper-slide">
        <img
          src="../../../assets/img/logos/logo-5.JPG"
          class="img-fluid section-five__logo"
          width="364px"
        />
      </div>
      <div class="swiper-slide">
        <img
          src="../../../assets/img/logos/logo-6.JPG"
          class="img-fluid section-five__logo"
          width="364px"
        />
      </div>
      <div class="swiper-slide">
        <img
          src="../../../assets/img/logos/logo-7.png"
          class="img-fluid section-five__logo"
          width="364px"
        />
      </div>
      <div class="swiper-slide">
        <img
          src="../../../assets/img/logos/logo-9.png"
          class="img-fluid section-five__logo"
          width="364px"
        />
      </div>
      <div class="swiper-slide">
        <img
          src="../../../assets/img/logos/logo-10.jpeg"
          class="img-fluid section-five__logo"
          width="364px"
        />
      </div>
      <div class="swiper-slide">
        <img
          src="../../../assets/img/logos/logo-11.jpeg"
          class="img-fluid section-five__logo"
          width="364px"
        />
      </div>
      <div class="swiper-slide">
        <img
          src="../../../assets/img/logos/logo-12.jpeg"
          class="img-fluid section-five__logo"
          width="364px"
        />
      </div>
      <div class="swiper-slide">
        <img
          src="../../../assets/img/logos/logo-13.jpeg"
          class="img-fluid section-five__logo"
          width="364px"
        />
      </div>
    </div>
    <!-- <div class="swiper-pagination section-five__pagination"></div> -->
  </div>
</div>

<!-- <div class="section-five__p">
  <p class="mt-4 mb-4">
    Administre fácilmente y de forma segura su edificio, condominio o
    residencia. Nuestro sistema de gestión de edificios online le permite ver
    toda la información en tiempo real de los guardias de seguridad, información
    de cada uno de los residentes, escaneo de documentos mediante foto y mucho
    más.
  </p>
  <p>
    Con Munily, la gestión de su edificio es más eficaz y menos costosa gracias
    a la experiencia de nuestro equipo aplicada en una sola aplicación.
  </p>
</div> -->
