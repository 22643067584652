import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { LayoutModule } from '../layout/layout.module';

import { ContentComponent } from './content/content.component';
import { LandingComponent } from './landing/landing.component';
import { ContactComponent } from './contact/contact.component';
import { FormContactComponent } from './form-contact/form-contact.component';
import { FooterComponent } from './footer/footer.component';

import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'ngx-swiper-wrapper';

/* import { YouTubePlayerModule } from '@angular/youtube-player';
import { YoutubeComponent } from '../youtube/youtube.component' */

const homeRoute : Routes = [
	{ path 	: '', component : LandingComponent },
	{ path	: 'contactus', component: ContactComponent }
];

@NgModule({
	declarations: [
		ContentComponent,
		FooterComponent,
		LandingComponent,
		ContactComponent,
		FormContactComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		LayoutModule,
		RouterModule.forChild(homeRoute),
		ReactiveFormsModule,
		SwiperModule,
		FormsModule,
		/* YouTubePlayerModule,
		YoutubeComponent */
	],
	exports : []
})
export class HomeModule { }
