<!-- Navbar Site-->
<app-navbar></app-navbar>
<!-- end Navbar Site-->

<div class="container-fluid">
    <!-- Inicio -->
    <app-content></app-content>
    <!-- end Inicio -->

    <!-- Section One -->
    <app-section-one></app-section-one>
    <!-- end Section One -->

    <!-- Section Three -->
    <app-section-three></app-section-three>
    <!-- end Section Three -->

    <!-- Section Four -->
    <app-section-four></app-section-four>

    <!-- Section Five -->
    <app-section-five></app-section-five>
    <!-- end Section Five -->

    <!-- Section FAQs -->
    <app-section-faqs></app-section-faqs>

    <!-- Footer -->
    <app-footer></app-footer>
    <!-- end Footer -->
</div>