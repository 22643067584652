import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageByDefault, LanguageCode } from './interfaces/tycInterface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  constructor(translate: TranslateService) {
    translate.addLangs([LanguageCode.spanish, LanguageCode.english]);
    let language = LanguageByDefault;
    if (translate.getBrowserLang() === LanguageCode.spanish) language = LanguageCode.spanish;

    translate.setDefaultLang(language);
  }
  
  title = 'new-munily-website';
}
