<div class="container-fluid">
  <!-- sectionContact -->
  <div class="row m-0">
    <div class="clipped-contacV2">
      <div class="row m-0">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
          <div class="contact__content">
            <img
              src="https://munily-public-cdn.s3.amazonaws.com/webpages/Mask+group.svg"
              class="contact__img"
            />
          </div>
        </div>
        <div class="w-75 col-md-6 ml-auto mr-auto contentList">
          Estás a punto de ser parte de las comunidades seguras y conectadas de Latinoamérica
        </div>
        <div class="ml-auto mr-auto list">
          En la prueba gratuita obtendrás:
          <ul class="mt-4 listChecks">
            <li class="sizeList"><img src="https://munily-public-cdn.s3.amazonaws.com/webpages/checkForm.png" alt="check"> Atención personalizada</li>
            <li class="sizeList"><img src="https://munily-public-cdn.s3.amazonaws.com/webpages/checkForm.png" alt="check"> Recorrido por cada una de las funcionalidades</li>
            <li class="sizeList"><img src="https://munily-public-cdn.s3.amazonaws.com/webpages/checkForm.png" alt="check"> Entenderás como se ajusta nuestra solución a tus necesidades.</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ovalo"></div>
    <div class="col-xs-12 col-sm-12 col-lg-6 contentForm">
      <div id="hubspotForm">
        <div class="col-md-6 mb-4" style="font-weight: 600; font-size: 18px; padding-left: 0 !important;">
          ¡Déjanos tus datos y <span style="font-weight: bold;">agendaremos una prueba gratuita!</span>
        </div>
        <form [formGroup]="newFormPage" class="form">
          <div class="form-group d-flex justify-content-lg-around columnInput">
            <div class="form-group col-md-6 pl-0 inputsIn">
              <select class="form-select styleSelect" id="country" style="color: #727A81;" formControlName="country" (change)="setValueChange($event)">
                <option hidden selected [selected]="isSelect">País</option>
                <option *ngFor="let item of countries">{{ item ? item : '' }}</option>
              </select>
            </div>
            <div class="form-group col-md-6 pr-0 inputsIn">
              <select class="form-select"  style="color: #727A81;" formControlName="city">
                <option hidden selected [selected]="isSelect">Ciudad</option>
                <option *ngFor="let item of cities">{{item}}</option>
              </select>
            </div>
          </div>
          <div class="form-group d-flex justify-content-lg-around columnInput">
            <div class="form-group col-md-6 pl-0 inputsIn">
              <input
                type="text"
                class="form-control"
                id="name"
                name="firstname"
                maxlength="200"
                placeholder="Nombre"
                formControlName="name"
              />
            </div>
            <div class="form-group col-md-6 pr-0 inputsIn">
              <input
                type="text"
                class="form-control"
                id="cargo"
                name="cargo"
                maxlength="200"
                placeholder="Cargo"
                formControlName="position"
              />
            </div>
          </div>
          <div class="form-group d-flex justify-content-lg-around columnInput">
            <div class="form-group col-md-6 pl-0 inputsIn">
              <input
                type="email"
                class="form-control"
                id="email"
                name="email"
                placeholder="Correo electrónico"
                formControlName="email"
              />
            </div>
            <div class="form-group col-md-6 pr-0 inputsIn">
              <input
                type="number"
                class="form-control"
                id="phone"
                name="phone"
                placeholder="Teléfono"
                formControlName="phone"
              />
            </div>
          </div>
          <div class="form-group">
            <select class="form-select" aria-label="Default select example" style="color: #727A81;" formControlName="serviceNeed">
              <option hidden selected [selected]="isSelect">¿Qué tanto necesitas este servicio?</option>
              <option *ngFor="let item of levelServiceNeedOpts">{{item}}</option>
            </select>
          </div>
          <div class="form-group">
            <select class="form-select" aria-label="Default select example" style="color: #727A81;" formControlName="experienceDoYouHave">
              <option hidden selected [selected]="isSelect">¿Cuánta experiencia tienes en tu cargo?</option>
              <option *ngFor="let item of experienceDoYouHaveOpts">{{item}}</option>
            </select>
          </div>

          <p>Al enviar este formulario estás aceptando nuestra <a href="https://munily.com/terms/data-treatment">política de privacidad</a> y tratamiento de datos personales</p>

          <div class="form-group">
            <div class="row">
              <div class="col-12 text-left">
                <button
                  type=""
                  (click)="senToCRM()"
                  class="btn btn-primary btn__btn-munily p-3"
                  style=" border-radius: 70px; padding-left: 29px !important;
                  padding-right: 29px !important;"
                >
                  Enviar mensaje
                </button>
              </div>
            </div>
          </div>
        </form>
        <div>

        </div>
      </div>
    </div>
    <div class="row space" style="position: relative; top: -4em;">
      <div class="w-75 ml-auto mr-auto contentTextWriteMe" style="margin-bottom: 5em;">
        <img src="https://munily-public-cdn.s3.amazonaws.com/webpages/contact1.png" alt="img1" class="ilustrators" style="position: relative; right: 16px;">
        <p class="ml-auto mr-auto textWriteMe">¡Estaremos felices de atenderte y resolver cualquier inquietud! Escríbenos, un asesor te atenderá de inmediato.</p>
      </div>
      <div style="margin-left: 11%; width: 85%;">
        <ul class="d-flex justify-content-between ml-auto mr-auto styleListImages">
          <li><img src="https://munily-public-cdn.s3.amazonaws.com/webpages/rocket-contact.png" alt="rocket" class="ilustrators"></li>
          <li style="margin-right: 100px;">
            <a href="https://api.whatsapp.com/message/UAOMAAOU6S7GP1?autoload=1&app_absent=0" class="btn btn-success btn-lg wppBtn">
              <img style="margin-bottom: 5px; margin-right: 3px;" src="https://munily-public-cdn.s3.amazonaws.com/webpages/whatsApp-white.png" alt="WhatsApp-icon"> Hablamos
            </a>
          </li>
          <li><img src="https://munily-public-cdn.s3.amazonaws.com/webpages/contact2.png" class="img2" alt="img2"></li>
        </ul>
      </div>
    </div>
  </div>
</div>
