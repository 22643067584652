import { Component, OnInit } from '@angular/core';
import { JsonLDService } from '../services/json-ld.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {

  constructor(
    private jsonLd: JsonLDService
  ) { }

  ngOnInit(): void {
    this.jsonLd.insertSchema(this.jsonLd.websiteSchema(), 'structured-data-org');
  }

  ngOnDestroy(): void {
    this.jsonLd.removeStructuredData();
  }

}
