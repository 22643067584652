<!-- SVG Section -->
<svg class="svg">
    <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
        <path d="M0,0.067 C0.063,0.133,0.125,0.2,0.313,0.133 Q0.75,0,1,0.133 L1,1 C0.688,0.533,0.25,0.733,0,0.4"></path>
        <!-- <path d="M1,0.2 C0.313,0.2,0.375,0.24,0,0.2 L0,0.6 C0.25,0.7,0.563,0.7,1,1 L1,0.2"></path> -->
    </clipPath>
</svg>
<!-- end SVG Section -->
<div class="container-elements">
    <div class="clipped"></div>
    <!-- Video Section -->
    <app-section-two></app-section-two>
    <!-- end Video Section -->
</div>