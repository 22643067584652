import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

	hasPath: boolean = false;

	constructor(private route: Router) {
		if (this.route.url == '/contactus')
			this.hasPath = true;
	}

	ngOnInit() {
	}
}
