import { Component, OnInit, AfterViewInit, AfterContentInit, AfterContentChecked, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements AfterContentChecked {

  notFooter: boolean = false;
  counter: number = 0
  constructor( private router: Router) {
  }

  ngAfterContentChecked(): void {
    if ( this.router.url === '/contact-us') {
      this.notFooter = true;
    } else if ( this.router.url === '/' ) {
      this.hiddenHeader()
    }
      else {
      this.notFooter = false ;
    }

  }

  hiddenHeader() {
    this.counter++
      if (this.counter > 2) {
        this.notFooter = true;
      }
  }

  scrollTo(selector) {
    let el = document.querySelector(selector);

    el.scrollIntoView({ behavior: "smooth" });

    return false;
  }

}
