<div class="section-faqs">
    <img src="/assets/img/faqs/wave-top.svg" alt="Wave" class="faqs-waves">

    <div class="content-faqs">
        <div class="container">
            <div class="row">
                <div class="col-12 faqs-heading">
                    <h2>Preguntas Frecuentes</h2>
                </div>

                <div class="col-12 faqs-items">
                    <div class="row">
                        <div class="col-md-5 col-12">
                            <ngb-accordion #acc="ngbAccordion" class="faqs-item">
                                <ngb-panel title="¿Qué es Munily?">
                                    <ng-template ngbPanelContent> Munily es un software para la administración de
                                        edificios y condominios. Brindamos soluciones de comunicación y seguridad de
                                        comunidades. </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" class="faqs-item">
                                <ngb-panel title="¿Cómo funciona Munily?">
                                    <ng-template ngbPanelContent> Conectamos a la administración, residentes y guardias
                                        a través de una App móvil para toda la gestión de la comunicación y la seguridad
                                        residencial. La administración también cuenta con un portal web. </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" class="faqs-item">
                                <ngb-panel title="¿Quién paga Munily?">
                                    <ng-template ngbPanelContent> La administración del residencial. </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" class="faqs-item">
                                <ngb-panel title="¿Cuántos condominios puedo administrar con Munily?">
                                    <ng-template ngbPanelContent> Cuantos desees. </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" class="faqs-item">
                                <ngb-panel title="¿Puedo elegir las funciones que me interesen solamente?">
                                    <ng-template ngbPanelContent>Claro, contáctanos para mayor información.
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                        </div>
                        <div class="col-md-5 col-12 offset-md-2">
                            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" class="faqs-item">
                                <ngb-panel title="¿Con qué medios de pago puedo abonar?">
                                    <ng-template ngbPanelContent>Transferencia bancaria, cheques y Paypal.</ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" class="faqs-item">
                                <ngb-panel
                                    title="¿Dónde puedo ver una demostración del programa para administrar edificios?">
                                    <ng-template ngbPanelContent>Haciendo click en solicita una demo al inicio de la
                                        página web.</ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" class="faqs-item">
                                <ngb-panel title="¿Cuántos usuarios son por apartamento?">
                                    <ng-template ngbPanelContent>Los usuarios en Munily son ilimitados.</ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" class="faqs-item">
                                <ngb-panel title="¿Tienen un periodo de prueba?">
                                    <ng-template ngbPanelContent>Por supuesto, la cortesía es de 30 días sin
                                        compromisos.</ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" class="faqs-item">
                                <ngb-panel title="¿Por qué Munily?">
                                    <ng-template ngbPanelContent>Te vamos a brindar el mejor servicio al cliente de
                                        América Latina y Munily es la App más amigable del mercado. De no ser así, te
                                        devolvemos el dinero.</ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                        </div>
                    </div>
                </div>
            </div>

            <img src="/assets/img/faqs/dots-path.svg" alt="" class="dots-path dots-path-1">
            <img src="/assets/img/faqs/dots-path.svg" alt="" class="dots-path dots-path-2">
            <img src="/assets/img/faqs/dots-path.svg" alt="" class="dots-path dots-path-3">
        </div>
    </div>

    <img src="/assets/img/faqs/wave-bottom.svg" alt="Wave" class="faqs-waves">
</div>