import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from "@angular/fire/compat";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import firebase from "firebase/compat/app";
import { SWIPER_CONFIG, SwiperModule } from 'ngx-swiper-wrapper';
import { ToastrModule } from 'ngx-toastr';
import { environment } from "src/environments/environment";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './commons/footer/footer.component';
import { HeaderComponent } from './commons/header/header.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { HomeModule } from "./home/home.module";
import { BenefitsComponent } from './home/sections/benefits/benefits.component';
import { CommunityHomeComponent } from './home/sections/community-home/community-home.component';
import { DownloadHomeComponent } from './home/sections/download-home/download-home.component';
import { FaqsHomeComponent } from './home/sections/faqs-home/faqs-home.component';
import { HeroHomeComponent } from './home/sections/hero-home/hero-home.component';
import { LogosCarouselHomeComponent } from './home/sections/logos-carousel-home/logos-carousel-home.component';
import { VideoHomeComponent } from './home/sections/video-home/video-home.component';
import { TermsMunilyPayComponent } from './terms-munily-pay/terms-munily-pay.component';
import { Termsv2Component } from './termsv2/termsv2.component';
import { YoutubeComponent } from './youtube/youtube.component';


firebase.initializeApp(environment.firebase);

const DEFAULT_SWIPER_CONFIG = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
  // return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    HeroHomeComponent,
    VideoHomeComponent,
    BenefitsComponent,
    CommunityHomeComponent,
    LogosCarouselHomeComponent,
    FaqsHomeComponent,
    DownloadHomeComponent,
    FooterComponent,
    ContactComponent,
    YoutubeComponent,
    Termsv2Component,
    TermsMunilyPayComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    SwiperModule,
    FormsModule,
    NgbModule,
    YouTubePlayerModule,
    HomeModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient, HttpClientModule]
      },
    }),
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    Document
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
