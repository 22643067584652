import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NavbarComponent } from './navbar/navbar.component';
import { ModalVehicleQpassComponent } from './modal-vehicle-qpass/modal-vehicle-qpass.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { httpLoaderFactory } from '../app.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [NavbarComponent, ModalVehicleQpassComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient, HttpClientModule]
      },
    }),
  ],
  exports: [NavbarComponent]
})

export class SharedModule { }
