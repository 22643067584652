<div class="contenedor">
  <div id="container mt-5 leftSide">
    <div class="dropdown mt-5 ml-5">
      <button
        class="btn dropdownCountries dropdown-toggle w-20"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {{ countrySelected }}
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li *ngFor="let country of countries" class="itemCountry">
          <span
            *ngIf="countrySelected !== country.name"
            (click)="chooseTyCByCountry(country)"
            class="dropdown-item"
            >{{ country.name }}</span
          >
        </li>
      </ul>
    </div>

    <div class="containerTyC mt-5"></div>
  </div>
  <hr *ngIf="mobileMode" />
  <div class="container rightSide">
    <ul>
      <li
        [class]="
          typeTyCSelected == tycType.value ? 'itemActive' : 'itemTyCType'
        "
        *ngFor="let tycType of tycTypes"
      >
        <span (click)="toogleTyCType(tycType.value, tycSpan)" class="tycType">
          {{ tycType.display }}</span
        >
        <hr *ngIf="tycType.value !== tycTypes[tycTypes.length - 1].value" />
      </li>
    </ul>
  </div>
</div>
