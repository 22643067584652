<div class="form__container" id="hubspotForm">
    <div class="form__title">Descubre la Comunidad Munily</div>
    <form [formGroup]="contactForm">
        <div class="form-group">
            <label for="nombre">Nombre</label>
            <input type="text" class="form-control" id="name" name="firstname" formControlName="name">
        </div>
        <div class="form-group">
            <label for="apellido">Apellido</label>
            <input type="text" class="form-control" id="lastname" name="lastname" formControlName="lastname">
        </div>
        <div class="form-group">
            <label for="telefono">Teléfono</label>
            <input type="number" class="form-control" id="phone" name='phone' formControlName="phone">
        </div>
        <div class="form-group">
            <label for="correo">Correo</label>
            <input type="email" class="form-control" id="email" name='email' formControlName="email">
        </div>
        <div class="form-group">
            <label for="discuss">¿Qué te gustaría ver en la reunión?</label>
            <textarea class="form-control" id="comment" name="comments" formControlName="comment" rows="3"></textarea>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-12 text-right">
                    <button type="submit"
                        (click)="sendTestPetition()"
                        class="btn btn-primary btn__btn-munily"
                        [disabled]="!contactForm.valid">Enviar</button>
                </div>
            </div>
        </div>
    </form>
</div>