import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class JsonLDService {
  static scriptType = 'application/json+ld';

  public websiteSchema = () => {
    return {
      '@context': 'https://schema.org',
      '@type': 'LocalBusiness',
      name: 'Munily',
      image: 'https://munily.com/assets/img/munily-logo-2.png',
      '@id': '',
      url: 'https://munily.com',
      telephone: '+50766587864',
      priceRange: '$',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Torre Bicsa, Avenida Balboa Piso 43 - Oficina 03',
        addressLocality: 'Panama',
        postalCode: '0816',
        addressCountry: 'PA',
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: 8.9759306,
        longitude: -79.5223704,
      },
      openingHoursSpecification: {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: '09:00',
        closes: '18:00',
      },
      sameAs: [
        'https://www.facebook.com/Munily-App-101110371523119/',
        'https://twitter.com/munily_app',
        'https://www.instagram.com/munily_app/',
        'https://www.linkedin.com/company/28160008/admin/',
        'https://wa.me/50766724603?text=%C2%A1Hola!%20Me%20interesa%20saber%20m%C3%A1s%20de%20Munily',
      ],
    };
  };

  public contactSchema = () => {
    return {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: [
        {
          '@type': 'Question',
          name: '¿Qué es Munily?',
          acceptedAnswer: {
            '@type': 'Answer',
            text: 'Munily es un software para la administración de edificios y condominios. Brindamos soluciones de comunicación y seguridad de comunidades.',
          },
        },
        {
          '@type': 'Question',
          name: '¿Cómo funciona Munily?',
          acceptedAnswer: {
            '@type': 'Answer',
            text: 'Conectamos a la administración, residentes y guardias a través de una App móvil para toda la gestión de la comunicación y la seguridad residencial. La administración también cuenta con un portal web.',
          },
        },
        {
          '@type': 'Question',
          name: '¿Quién paga Munily?',
          acceptedAnswer: {
            '@type': 'Answer',
            text: 'La administración del residencial.',
          },
        },
        {
          '@type': 'Question',
          name: '¿Cuántos condominios puedo administrar con Munily?',
          acceptedAnswer: {
            '@type': 'Answer',
            text: 'Cuantos desees.',
          },
        },
        {
          '@type': 'Question',
          name: '¿Puedo elegir las funciones que me interesen solamente?',
          acceptedAnswer: {
            '@type': 'Answer',
            text: 'Claro, contáctanos para mayor información.',
          },
        },
        {
          '@type': 'Question',
          name: '¿Con qué medios de pago puedo abonar?',
          acceptedAnswer: {
            '@type': 'Answer',
            text: 'Transferencia bancaria, cheques y Paypal.',
          },
        },
        {
          '@type': 'Question',
          name: '¿Dónde puedo ver una demostración del programa para administración de edificios?',
          acceptedAnswer: {
            '@type': 'Answer',
            text: 'Haciendo click en solicita una demo al inicio de la página web.',
          },
        },
        {
          '@type': 'Question',
          name: '¿Cuántos usuarios son por apartamento?',
          acceptedAnswer: {
            '@type': 'Answer',
            text: 'Los usuarios en Munily son ilimitados.',
          },
        },
        {
          '@type': 'Question',
          name: '¿Tienen un periodo de prueba?',
          acceptedAnswer: {
            '@type': 'Answer',
            text: 'Por supuesto, la cortesía es de 30 días sin compromisos.',
          },
        },
        {
          '@type': 'Question',
          name: '¿Por qué Munily?',
          acceptedAnswer: {
            '@type': 'Answer',
            text: 'Te vamos a brindar el mejor servicio al cliente de América Latina y Munily es la App más amigable del mercado. De no ser así, te devolvemos el dinero.',
          },
        },
      ],
    };
  };

  constructor(@Inject(Document) private _document: Document) {}

  removeStructuredData(): void {
    const els = [];
    ['structured-data', 'structured-data-org'].forEach((c) => {
      els.push(...Array.from(document.head.getElementsByClassName(c)));
    });
    els.forEach((el) => document.head.removeChild(el));
  }

  insertSchema(
    schema: Record<string, any>,
    className = 'structured-data'
  ): void {
    console.log(schema);
    let script;
    let shouldAppend = false;
    if (document.head.getElementsByClassName(className).length) {
      script = document.head.getElementsByClassName(className)[0];
    } else {
      script = document.createElement('script');
      shouldAppend = true;
    }
    script.setAttribute('class', className);
    script.type = JsonLDService.scriptType;
    script.text = JSON.stringify(schema);
    if (shouldAppend) {
      document.head.appendChild(script);
    }
  }
}
