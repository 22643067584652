export enum AmplitudeEvent {
  providerSavingsSelected = '[App Resident]  Provider Savings Selected',
  fastpassOpened = '[Web Visitor]  Fastpass Opened',
  registerStarted = '[Web Visitor] Register Started',
  fastpassQrDataPolicyAccepted = '[Web Visitor] Fastpass QR Data Policy Accepted',
  nameCompleted = '[Web Visitor] Name Completed',
  fastpassQrCreated = '[Web Visitor] Fastpass QR Created',
  categorySavingsSelected = '[App Resident]  Category Savings Selected',
  demoRquested = '[Web Lead] Demo Requested',
  formSent = '[Web Lead] Form Sent',
  fastpassUpdatedData = "[Web Visitor] FastPass Updated Data",
  changeCommunitySelected = "[Web Resident]Change Community Selected",
  loginSelected = "[Web Resident] Login Selected",
  communityChanged = "[Web Resident] Community Changed",
  updateDataOptionSelected = "[Web Resident] Update Selected",
  updateDataName = "[Web Resident] Name Updated",
  updateDataPhoto = "[Web Resident] Photo Updated",
  updateDataPhone = "[Web Resident] Phone Number Updated",
  appDownloaded = "[Web Resident] App Downloaded",
  forgotPassword = "[Web Resident] Forgot password Selected",
  vehiclePlateRegistrationQpass = "[Web Visitor] Plates Registered",
  pedestrianEntryRegisteredQpass = "[Web Visitor] Pedestrian Entry Registered"
}

export class AmplitudeGenericEvent {
  event_type: AmplitudeEvent;
  user_id: string;
  event_properties?: ProviderSavingsSelected | CategorySavingsSelected | FastpassOpened | DemoRequested | FormSent | FastPassUpdatedData | FastPassTYCAccepted | FastPassNameRegistered | FastPassIDRegistered | TypeUpdatedAvatar;
}

export class ProviderSavingsSelected {
  'Provider Savings Type': string;
}

export class CategorySavingsSelected {
  'Category Savings Type': string;
}

export class FastpassOpened {
  'Visitor Type': VisitorType;
}

export enum VisitorType {
  new = 'New',
  current = 'Current',
}

export class DemoRequested {
  'Number of Users click': string;
}

export class FormSent {
  'Country': string;
  'Administrator Experience': string;
  'City': string;
  'Service Need': string;
}

export enum formSentStructure {
  country = 'Country',
  adminExperience = 'Administrator Experience',
  city = 'City',
  serviceNeed = 'Service Need'
}

export class TypeUpdatedAvatar {
  "Action Photo": string
}

export class FastPassUpdatedData {
  'FastPass Updated Data': string;
}

export enum FastPassUpdateDataValues {
  new = 'New access',
  update = 'Updated data'
}

export class FastPassTYCAccepted {
  'T&C accepted': FastPassUpdateDataValues
}

export class FastPassNameRegistered {
  'Name registered': FastPassUpdateDataValues
}

export class FastPassIDRegistered {
  'ID registered': FastPassUpdateDataValues
}
