import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-video-home',
  templateUrl: './video-home.component.html',
  styleUrls: ['./video-home.component.sass'],
  animations: [
    trigger('visibilityChanged', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('shown => hidden', animate('600ms')),
      transition('hidden => shown', animate('300ms')),
    ])
  ]
})
export class VideoHomeComponent implements OnInit {

  visiblityState = 'shown';

  @ViewChild('videoPlayer') videoplayer: ElementRef;

  constructor() { }

  ngOnInit(): void {
    
  }

  playVideo(): void {
    const video = this.videoplayer.nativeElement;

    if (video.paused) {
      video.play();
      this.visiblityState = 'hidden';
    } else {
      video.pause();
      this.visiblityState = 'shown';
    }
  }

}
