<svg class="svg">
    <clipPath id="my-clip-path-two" clipPathUnits="objectBoundingBox">
        <path d="M1,0.4 C0.75,0.067,0.375,0.267,0,0 L0,0.867 C0.438,1,0.5,0.8,1,1 L1,0.4"></path>
        <!-- <path d="M1,0.1 C0.75,0.017,0.375,0.067,0,0 L0,1 C1,1,0.5,1,1,1 L1,0.1"></path> -->
    </clipPath>
</svg>

<div class="container-testimonys">
    <div class="clipped-two"></div>
    <app-testimony></app-testimony>
</div>
