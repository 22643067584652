import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { Termsv2Component } from './termsv2/termsv2.component';
import { TermsMunilyPayComponent } from './terms-munily-pay/terms-munily-pay.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'contact-us', component: ContactComponent },
  { path: 'terms/data-treatment', component: Termsv2Component },
  { path: 'terms/munily-pay/data-treatment', component: TermsMunilyPayComponent },
  {
    path: 'plans',
    loadChildren: () =>
      import('./plans/plans.module').then((m) => m.PlansModule),
  },
  {
    path: 'us-us',
    loadChildren: () => import('./us/us.module').then((m) => m.UsModule),
  },
  {
    path: 'fresh-eat',
    loadChildren: () =>
      import('./public/public.module').then((m) => m.PublicModule),
  },
  {
    path: 'colombia',
    loadChildren: () =>
      import('./public/landing-colombia-routing.module').then(
        (m) => m.LandingColombiaRoutingModule
      ),
  },
  {
    path: 'sage50',
    loadChildren: () =>
      import('./public/landing-sage50-routing.module').then(
        (m) => m.LandingSage50RoutingModule
      ),
  },
  {
    path: 'self-settings',
    loadChildren: () =>
      import('./self-settings/self-settings.module').then(
        (m) => m.SelfSettingsModule
      ),
  },
  {
    path: 'costa-rica',
    loadChildren: () =>
      import('./public/landing-costa-rica-routing.module').then(
        (m) => m.LandingCostaRicaRoutingModule
      ),
  },
  {
    path: 'change-community',
    loadChildren: () => import('./change-comunity/change-comunity.module').then((s) => s.ChangeComunityModule),
  },
  {
    path: 'eventos',
    loadChildren: () => import('./public/eventos/eventos-routing.module').then((m) => m.EventosRoutingModule),
  },
  {
    path: 'savings',
    loadChildren: () => import('./public/landing-savings-routing.module').then((s) => s.LandingSavingsRoutingModule),
  },
  {
    path: 'public',
    loadChildren: () => import('./public/public-routing.module').then((s) => s.PublicRoutingModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
